import React, {useEffect, useState} from 'react';
import {
    Grid,
    Container,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Card,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

import {useFormik} from "formik";
import * as yup from "yup"

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import BeenhereTwoToneIcon from '@material-ui/icons/BeenhereTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';

import hero6 from '../assets/images/hero-bg/hero-1.jpg';

import {fetchAPI} from "../api";

const validationSchema = yup.object({
    full_name: yup
        .string('Ingresa tu nombre')
        .required('Nombre es obligatorio'),
    email: yup
        .string('Ingresa tu email')
        .email('Ingresa un email valido')
        .required('Email es obligatorio'),
    password: yup
        .string('Ingresa tu Password')
        .min(6, 'Debe ser de más de 6 caracteres')
        .required('Password es obligatorio'),
    passwordConfirm: yup
        .string('Ingresa tu Password')
        .required('Password es obligatorio')
        .oneOf(
            [yup.ref('password'), null],
            'Passwords deben ser iguales',
        )
})

function Register(props) {

    useEffect(() => {
        document.title = "Registrarse en Gungo"
    }, [])

    const [subscribe, setSubscribe] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [okMessage, setOkMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChangeSubscribe = (event) => {
        setSubscribe(event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            full_name: '',
            email: '',
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            setLoading(true)
            delete values.passwordConfirm
            const params = {user: values}
            fetchAPI('POST', '/signup', params, false)
                .then(data => {
                    if (data.id > 0) {
                        // setConfirmation({
                        //     open: true,
                        //     type: 'success',
                        //     message: `Recibimos tu Mensaje. Gracias por contactarnos`
                        // })
                        resetForm()
                        setOkMessage("Usuario Creado Correctamente")
                    } else {
                        setErrorMessage("hubo un error al ingresar tus datos")
                    }
                })
                .catch(e => setErrorMessage("hubo un error al ingresar tus datos"))
            setLoading(false)
        },
    })

    return (
        <div className="app-wrapper min-vh-100 bg-white">

            <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div
                        className="bg-composed-wrapper--image opacity-6"
                        style={{ backgroundImage: 'url(' + hero6 + ')' }}
                    />
                    <div className="bg-composed-wrapper--bg bg-second opacity-7" />
                    <div className="bg-composed-wrapper--content p-3 p-md-5">
                        <Container>
                            <Card className="rounded-sm modal-content p-3 bg-white-10">
                                <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                                    <Grid container spacing={0}>
                                        <Grid
                                            item
                                            md={6}
                                            className="d-flex align-items-center justify-content-center flex-column">
                                        <form onSubmit={formik.handleSubmit} className="w-100">
                                            <div className="divider-v divider-v-lg d-none d-lg-block" />
                                            <div className="text-center mt-4 p-4 w-100">
                                                <Typography variant="h3" className="font-size-xxl mb-1 font-weight-bold">
                                                    Registrarse
                                                </Typography>
                                                <Typography variant="subtitle1" className="mb-0 text-black-50">
                                                    Registrate en Gungo
                                                </Typography>
                                                {errorMessage ? <Alert elevation={6} variant="filled" severity="warning">{errorMessage}</Alert> : null}
                                                {okMessage ? <Alert elevation={6} variant="filled" severity="success">{okMessage}</Alert> : null}
                                            </div>
                                            {!okMessage && (
                                            <div className="p-4 w-100">
                                                <div className="mb-3">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="full_name"
                                                        label="Nombre"
                                                        value={formik.values.full_name}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                                                        helperText={formik.touched.full_name && formik.errors.full_name}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonOutlineTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="email"
                                                        label="Email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                                        helperText={formik.touched.email && formik.errors.email}
                                                        disabled={loading}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MailOutlineTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="password"
                                                        label="Password"
                                                        type="password"
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                                        helperText={formik.touched.password && formik.errors.password}
                                                        disabled={loading}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="passwordConfirm"
                                                        label="Repite tu Contraseña"
                                                        type="password"
                                                        value={formik.values.passwordConfirm}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                                                        helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={subscribe}
                                                                onChange={handleChangeSubscribe}
                                                                value="checked"
                                                                color="primary"
                                                            />
                                                        }
                                                        className="font-size-md"
                                                        label="Acepto recibir correos de Gungo"
                                                    />
                                                </div>
                                                <div className="text-center py-4">
                                                    <Button
                                                        disabled={loading}
                                                        type="submit"
                                                        className="btn-second font-weight-bold w-50 my-2 py-3">
                                                        Registrarse
                                                    </Button>
                                                </div>
                                                <div className="text-center text-black-50 mt-3">
                                                    Ya tienes tu cuenta?{' '}
                                                    <Link
                                                        to="/login"
                                                        className="text-first">
                                                        Ingresa
                                                    </Link>
                                                </div>
                                            </div>
                                            )}
                                        </form>
                                            {okMessage && (
                                                <div className="p-4 w-100">
                                                    <div className="text-center mt-3">
                                                        <Typography variant="h6" className="mb-0 text-black-50">
                                                            Listo! Ya tienes tu cuenta ahora ve a
                                                        </Typography>
                                                        <Link
                                                            to="/login">
                                                            <Button
                                                                endIcon={<ChevronRightTwoToneIcon />}
                                                                className="btn-primary font-weight-bold w-50 my-2 py-3">
                                                                Iniciar Sesión
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            className="d-flex align-items-center justify-content-center flex-column bg-secondary">
                                            <div className="p-3">
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <FavoriteTwoToneIcon style={{color: "red"}} />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Favoritos
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Guarda tus centros de buceo favoritos.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <BeenhereTwoToneIcon style={{color: "green"}} />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Check in
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Registra tus visitas en los centros de buceo y lleva un registro de cuantas veces has buceado a lo largo de Chile, mientras desbloquea desafios. En cuantas regionse has buceado? cuantos centros distintos conoces?
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <StarTwoToneIcon style={{color: "#ffb400"}}  />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Reviews
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Califica los centros de buceo que has visitado y ve lo que otros buceadores opinan.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Card>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;