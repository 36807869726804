import React from "react";

import {Grid} from "@material-ui/core";
import CenterFacility from "./CenterFacility";

const CenterFacilities = (props) => {

    return (
        <Grid container spacing={2} className="p-2">
            <Grid item xs={12}>
                <h3>Servicios</h3>
                { (props.facilities === undefined || props.facilities.length === 0)
                    ?
                    (<div
                        className="m-2 btn-input-facilities ver-mas">
                        <div className="font-weight-bold mt-2">Sin Servicios Informados</div>
                    </div>)
                    :
                    props.facilities.map((f) => (<CenterFacility key={f.slug} {...f}  />))
                }
                {/*{*/}
                {/*    props.facilities &&*/}
                {/*    (*/}
                {/*        <div*/}
                {/*            className="m-2 btn-input-facilities ver-mas">*/}
                {/*            <div className="font-weight-bold mt-2">Ver Todas...</div>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}
                <div className="divider" />
            </Grid>
        </Grid>
    );
}

export default CenterFacilities;