import React from "react";

const CenterAvatar = (props) => {
    const setImageURL = (slug, logo) => {
        let url = '';
        if (logo && slug) {
            url = "https://cdn.gungo.cl/gungo-dev/uploads/center/logo/"+slug+"/thumb_"+logo;
        } else {
            url = "https://cdn.gungo.cl/gungo/web/gungo-512.png"
        }
        return url;
    };

    return(
        <div className="avatar-icon-wrapper mr-2">
            <div className="avatar-icon">
                <img alt={props.slug} src={setImageURL(props.slug, props.logo)} />
            </div>
        </div>
    );
};

export default CenterAvatar;