import React from "react"
import { Card, CardContent } from "@material-ui/core"
import Rating from '@material-ui/lab/Rating'

const CenterReviewCard = (props) => {

    let comment = ''
    if (props.extend) {
        comment = props.comment
    } else {
        comment = props.comment.substring(0, 100) + "..."
    }
    return (
        <Card className="mb-3">
            <CardContent>
                <div className="align-box-row align-items-start">
                    <div>
                        <Card className="card-transparent">
                            <a
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="card-img-wrapper rounded">
                                <img
                                    src="https://cdn.gungo.cl/gungo/default/user-avatar.png"
                                    className="card-img-top rounded-circle"
                                    style={{ width: 80 }}
                                    alt={props.user_name}
                                />
                            </a>
                        </Card>
                    </div>
                    <div className="pl-4">
                        <div className="mb-3 text-warning font-size-lg">
                        <Rating
                            readOnly
                            value={props.rating}
                        />
                        </div>
                        <blockquote className="my-3 text-black-50">
                            {comment}
                        </blockquote>
                        <div className="font-size-lg font-weight-bold">
                            {props.user_name}
                            <small className="text-black-50 pl-2">
                                {(props.user_name === 'Nombre Oculto') ?  '' : `(${props.user_dives} buceos en este centro)`}
                            </small>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default CenterReviewCard