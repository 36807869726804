import React, {useEffect, useState} from "react";

import {Grid, Card, TextField, Button, Container, Snackbar, Typography} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup"
import {fetchAPI} from "../api";
import MuiAlert from "@material-ui/lab/Alert";

const validationSchema = yup.object({
    name: yup
        .string('Ingresa tu nombre')
        .required('Nombre es obligatorio'),
    email: yup
        .string('Ingresa tu email')
        .email('Ingresa un email valido')
        .required('Email es obligatorio'),
    centerName: yup
        .string('Ingresa el nombre del centro')
        .required('Nombre es obligatorio'),
    centerEmail: yup
        .string('Ingresa el email del centro')
        .required('Email es obligatorio'),
    centerURL: yup
        .string('Ingresa la dirección web del centro')
        .url('Ingresa una dirección web valida ejemplo: http://gungo.cl')
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FormAddNewCenter = () => {
    useEffect(() => {
        document.title = "Nuevo Centro - Gungo"
    }, [])

    const [confirmation, setConfirmation] = useState({
        open: false,
        message: '',
        type: ''
    })
    const handleCloseConfirmation = () => {
        setConfirmation((s) => ({ ...s, open: false }))
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            centerName: '',
            centerEmail: '',
            centerURL: '',
            centerIG: '',
            centerFacebook: '',
            centerRegion: '',
            centerAddress: '',
            centerDescription: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            fetchAPI('POST', '/forms/newcenter', values, false)
                .then(data => {
                    if (data.sent === "ok") {
                        setConfirmation({
                            open: true,
                            type: 'success',
                            message: `Recibimos tu Mensaje. Gracias por contactarnos`
                        })
                        resetForm()
                    }
                })
        },
    })

    return (
        <Container fixed maxWidth="md">

            <Card className="p-4 mt-4">
                <Typography variant="h3" className="mb-3">Solicitud de nuevo centro en Gungo</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Datos de contacto</Typography>
                            <Typography variant="subtitle2">Quien solicita agregar el centro</Typography>
                            <div className="divider" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="name"
                                name="name"
                                label="Nombre"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Nuevo Centro</Typography>
                            <Typography variant="subtitle2">Información del nuevo centro de buceo</Typography>
                            <div className="divider" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerName"
                                name="centerName"
                                label="Nombre"
                                value={formik.values.centerName}
                                onChange={formik.handleChange}
                                error={formik.touched.centerName && Boolean(formik.errors.centerName)}
                                helperText={formik.touched.centerName && formik.errors.centerName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerEmail"
                                name="centerEmail"
                                label="Email de Contacto"
                                value={formik.values.centerEmail}
                                onChange={formik.handleChange}
                                error={formik.touched.centerEmail && Boolean(formik.errors.centerEmail)}
                                helperText={formik.touched.centerEmail && formik.errors.centerEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerURL"
                                name="centerURL"
                                label="Dirección Web"
                                value={formik.values.centerURL}
                                onChange={formik.handleChange}
                                error={formik.touched.centerURL && Boolean(formik.errors.centerURL)}
                                helperText={formik.touched.centerURL && formik.errors.centerURL}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerIG"
                                name="centerIG"
                                label="Instagram"
                                value={formik.values.centerIG}
                                onChange={formik.handleChange}
                                error={formik.touched.centerIG && Boolean(formik.errors.centerIG)}
                                helperText={formik.touched.centerIG && formik.errors.centerIG}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerFacebook"
                                name="centerFacebook"
                                label="Facebook"
                                value={formik.values.centerFacebook}
                                onChange={formik.handleChange}
                                error={formik.touched.centerFacebook && Boolean(formik.errors.centerFacebook)}
                                helperText={formik.touched.centerFacebook && formik.errors.centerFacebook}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Ubicación</Typography>
                            <Typography variant="subtitle2">Dónde opera o funciona este centro</Typography>
                            <div className="divider" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerRegion"
                                name="centerRegion"
                                label="Región"
                                value={formik.values.centerRegion}
                                onChange={formik.handleChange}
                                error={formik.touched.centerRegion && Boolean(formik.errors.centerRegion)}
                                helperText={formik.touched.centerRegion && formik.errors.centerRegion}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerAddress"
                                name="centerAddress"
                                label="Dirección"
                                value={formik.values.centerAddress}
                                onChange={formik.handleChange}
                                error={formik.touched.centerAddress && Boolean(formik.errors.centerAddress)}
                                helperText={formik.touched.centerAddress && formik.errors.centerAddress}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Descripción</Typography>
                            <Typography variant="subtitle2">Se mostrará como información en Gungo</Typography>
                            <div className="divider" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="centerDescription"
                                name="centerDescription"
                                label="Descripción"
                                multiline
                                rows={4}
                                value={formik.values.centerDescription}
                                onChange={formik.handleChange}
                                error={formik.touched.centerDescription && Boolean(formik.errors.centerDescription)}
                                helperText={formik.touched.centerDescription && formik.errors.centerDescription}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Adicional</Typography>
                            <Typography variant="subtitle2">Dejanos información adicional</Typography>
                            <div className="divider" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="message"
                                name="message"
                                label="Mensaje"
                                multiline
                                rows={4}
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                error={formik.touched.message && Boolean(formik.errors.message)}
                                helperText={formik.touched.message && formik.errors.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                size="large"
                                type="submit"
                                className="btn-primary py-3">
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            <Snackbar open={confirmation.open} autoHideDuration={6000} onClose={handleCloseConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseConfirmation} severity={confirmation.type}>
                    {confirmation.message}
                </Alert>
            </Snackbar>
        </Container>
    )

}

export default FormAddNewCenter