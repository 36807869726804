import React, { useEffect, useState } from "react";
import {Grid} from "@material-ui/core";
import CardCenter from "./CardCenter";
import { fetchAPI } from "../api";

const FeaturedCenters = () => {
    const [featured, SetFeatured] = useState([]);
    useEffect(() => {
        fetchAPI('GET', '/featured_centers','',false)
        .then(data => {
            SetFeatured(data)
        }).catch(error =>
            console.log("error al conectar con API")
        )
    }, []);
    return(
        <Grid container spacing={6} className="pt-4 mt-2">
            <Grid item xs={12}>
                <h3 className="font-weight-bold">Centros Destacados</h3>
            </Grid>
            {featured.map((featured) => (
                <Grid key={`grid-${featured.id}`} item lg={3} md={6}>
                    <CardCenter key={featured.id} {...featured}  />
                </Grid>
            ))}
        </Grid>
    );
}

export default FeaturedCenters;
