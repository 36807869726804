import React, {useEffect, useState} from "react";
import {Divider, Grid} from "@material-ui/core";

import Badge from "./Badge";
import {fetchAPI} from "../api";
import {useAuthState} from "../Context";

const Badges = () => {
    const userDetails = useAuthState()
    const [badges, setBadges] = useState();

    useEffect(() => {
        let mounted = true;
        fetchAPI('GET', '/badges', '', false)
            .then(data => {
                if (mounted){
                    setBadges(data.badges);
                }
            })
        return () => mounted = false;
    }, [])

    const [userBadges, setUserBadges] = useState({})
    useEffect(() => {

        fetchAPI('GET', '/users/me/badges', '', true)
            .then(data => {
                setUserBadges(data)
            })
    }, [userDetails])

    const renderBadge = (badges) => {
        let badgeList = [];
        Object.entries(badges).forEach(([key, value]) => {
            badgeList.push(
                <Grid key={key} item xs={12}>
                    <Divider className="my-2" />
                    <h3>{value.name}</h3>
                </Grid>
            );
            Object.entries(value.levels).forEach(([lk, vk]) => {
                let active = false;
                if (userBadges && userBadges[key]) {
                    if (parseInt(userBadges[key]['level']) >= parseInt(lk)) {
                        active = true;
                    }
                }
                let componentKey = key + "-" + lk;
                badgeList.push(<Badge key={componentKey} active={active} points={vk.goal} icon={value.image} unit={value.unit}  />);
            });
        });
        return badgeList;
    };

    return (
        <Grid container spacing={1}>
            {badges ? renderBadge(badges) : <h1>Cargando...</h1>}
        </Grid>
    );
};

export default Badges;