import React from "react";

import {Grid} from "@material-ui/core";
import parse from "html-react-parser"

const CenterDescription = (props) => {
    return (
        <Grid container spacing={2} className="p-2">
            <Grid item md={2}>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <div className="avatar-icon-wrapper shadow-lg border-white rounded mx-3 my-1 border-3">
                        <div className="avatar-icon shadow-none d-90 rounded">
                            <img alt={props.name} src={props.logo_thumb} />
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item md={10}>
                <h2>{props.name}</h2>
                <div>{props.description ? parse(props.description.toString()) : ''}</div>
            </Grid>
            <div className="divider" />
        </Grid>
    );
}

export default CenterDescription;