import React, {useEffect} from "react"
import {Container, Grid, Typography} from "@material-ui/core";

import hero from  '../assets/images/hero-bg/hero-2.jpg'

const Privacy = () => {

    useEffect(() => {
        document.title = "Politicas de Privacidad - Gungo"
    }, [])

    return (
        <div
            className="hero-wrapper bg-composed-wrapper bg-second z-below">
            <div className="hero-wrapper--content flex-column">
                <div className="shape-container-bottom-2 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
                    </svg>
                </div>
                <div
                    className="bg-composed-wrapper--image opacity-3"
                    style={{ backgroundImage: 'url(' + hero + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-night-sky opacity-4" />
                <div className="bg-composed-wrapper--content pb-5">
                    <Container fixed maxWidth="md" className="py-4">
                        <div className="py-5 py-sm-0">
                            <Typography variant="h4" className="font-weight-bold text-white mb-3">POLÍTICA DE PRIVACIDAD DEL SITIO WEB GUNGO.CL</Typography>
                            <Grid item xs={10}>
                                <Typography variant="h5" className="font-weight-bold text-white mb-3">I. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">El sitio web <strong>GUNGO.CL</strong>, en adelante "<strong>GUNGO</strong>", "<strong>GUNGO.CL</strong>" o "el sitio web" indistintamente, pone en conocimiento de las personas que hagan uso del mismo, en adelante "personas usuarias", la presente política de privacidad y protección de los datos personales.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Esta política de privacidad y protección de los datos personales forma parte de los Términos y Condiciones Generales de Uso del sitio web <strong>GUNGO.CL</strong>. Para mayor información, revise los Términos y Condiciones Generales de Uso contenidos en el sitio web.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La lectura de la misma le permitirá a las personas usuarias conocer el modo en que <strong>GUNGO</strong> recolecta, trata y protege de sus datos personales.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">El acceso, uso y permanencia en el sitio web <strong>GUNGO.CL</strong> implica la aceptación de la presente política de privacidad.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">De particular importancia resultan la aplicación de la Ley N° 19.628 de Protección de Datos Personales y la Ley N° 19.496 sobre Derechos del Consumidor.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Esta política, en lo que no contraviene la legislación chilena, está adaptada al Reglamento Europeo de Protección de Datos (RGPD).</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">1. Definiciones</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>a. Almacenamiento de datos: conservación o custodia de datos en un registro, banco o base de datos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>b. Dato estadístico: aquel que en su origen, o como consecuencia de su tratamiento, no puede ser asociado a un titular identificado o identificable.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>c. Datos de carácter personal o datos personales: aquellos relativos a cualquier información concerniente a personas naturales, identificadas o identificables.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>d. Datos sensibles: aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos y la vida sexual.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>e. Registro, banco o base de datos: conjunto organizado de datos de carácter personal, sea automatizado o no y cualquiera sea la forma o modalidad de su creación u organización, que permita relacionar los datos entre sí, así como realizar todo tipo de tratamiento de datos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>f. Responsable del registro, banco o base de datos: la persona natural o jurídica a quien compete las decisiones relacionadas con el tratamiento de los datos de carácter personal, también conocido como responsable del tratamiento de datos personales.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>g. Titular de los datos: persona natural a la que se refieren los datos de carácter personal.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>h. Tratamiento de datos: cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal, o utilizarlos en cualquier otra forma.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">2. Principios aplicables al tratamiento de los datos personales</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">El tratamiento de los datos personales de las personas usuarias se someterá a los siguiente principios:</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>a. Principio de licitud, lealtad y transparencia: se requerirá en todo momento el consentimiento de la persona usuaria previa información completamente transparente de los fines para los cuales se recogen los datos personales.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>b. Principio de limitación de la finalidad: los datos personales serán recogidos con fines determinados, explícitos y legítimos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>c. Principio de minimización de datos: los datos personales recogidos serán únicamente los estrictamente necesarios en relación con los fines para los que son tratados.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>d. Principio de exactitud: los datos personales deben ser exactos y serán siempre actualizados.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>e. Principio de limitación del plazo de conservación: los datos personales solo serán mantenidos de forma que se permita la identificación de la persona usuaria durante el tiempo necesario para los fines de su tratamiento.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>f. Principio de integridad y confidencialidad: los datos personales serán tratados de manera que se garantice su seguridad y confidencialidad.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>g. Principio de responsabilidad proactiva: la persona responsable del tratamiento será responsable de asegurar que los principios anteriores se cumplan.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify"><strong>3. Responsable</strong> <strong>del registro, banco o base de datos</strong></p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La persona responsable del tratamiento de los datos personales recogidos a través del sitio web <strong>GUNGO</strong> es el equipo de desarrollo.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos para tomar contacto con la persona responsable son:</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>Correo electrónico: <strong>gungo@gungo.cl</strong></p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">4. Recolección y registro de datos de carácter personal y finalidad de su tratamiento</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos personales obtenidos por <strong>GUNGO</strong> mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestras bases de datos con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre <strong>GUNGO</strong> y las personas usuarias, o para el mantenimiento de la relación que se establezca en los formularios que éstas rellenen, o para atender una solicitud o consulta de las mismas</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">En concreto, los datos de las personas usuarias serán obtenidos por <strong>GUNGO</strong> a través de la o las siguientes acciones:</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}><span id="span_id_modoobtencion">al momento de registrarse y completar los datos. Los registros de checkins de visitas a centros de buceo.</span></p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">5. Categoría de datos personales </Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Las categorías de datos que se tratan en <strong>GUNGO</strong> son únicamente datos identificativos. En ningún caso se tratan categorías de datos personales de carácter sensibles, como el estado de salud de las personas o sus opiniones políticas o creencias religiosas.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice, exista consentimiento de la persona titular de dichos datos o éstos sean datos necesarios para la determinación u otorgamiento de beneficios de salud que correspondan a sus titulares.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">6. Uso de datos personales </Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos personales deben utilizarse solamente para los fines para los cuales hubieren sido recolectados, salvo que provengan o se hayan recolectado de fuentes accesibles al público.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">No pueden ser objeto de tratamiento los datos sensibles, salvo cuando la ley lo autorice, exista consentimiento del titular o sean datos necesarios para la determinación u otorgamiento de beneficios de salud que correspondan a sus titulares.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La persona usuaria tendrá derecho a retirar su consentimiento en cualquier momento. Será tan fácil retirar el consentimiento como darlo. Como regla general, el retiro del consentimiento no condicionará el uso del sitio web.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">En las ocasiones en las que la persona usuaria deba o pueda facilitar sus datos a través de formularios para realizar consultas, solicitar información o por motivos relacionados con el contenido del sitio web, se le informará en caso de que la cumplimentación de alguno de ellos sea obligatorio debido a que los mismos sean imprescindibles para el correcto desarrollo de la operación realizada.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">7. Período de retención de los datos personales </Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos personales solamente serán retenidos durante el tiempo mínimo necesario para los fines de su tratamiento y, en todo caso, únicamente durante el siguiente plazo: <strong>1 año</strong>, o hasta que la persona usuaria solicite su supresión.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">En el momento en que se obtengan los datos personales se informará a la persona usuaria sobre el plazo durante el cual se conservarán los datos personales o, cuando eso no sea posible, los criterios utilizados para determinar este plazo.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">8. Destinatarios de los datos personales</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos personales de las personas usuarias no serán compartidos, vendidos, cedidos, arrendados, comercializados o transmitidos de modo alguno con terceras personas, salvo en los casos que la ley lo exija.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">9. Datos personales de menores de edad</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Solamente las personas mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por <strong>GUNGO</strong>.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Si se trata de una persona menor de 14 años será necesario el consentimiento de los padres o representantes legales o por quien tiene a su cargo el cuidado personal del niño o niña, salvo que expresamente lo autorice o mandate la ley.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos sensibles de las personas adolescentes menores de 16 años, solamente se podrán tratar con el consentimiento otorgado por sus padres o representantes legales o quien tiene a su cargo el cuidado personal del menor, salvo que expresamente lo autorice o mandate la ley.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">10. Secreto y seguridad de los datos personales</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify"><strong>GUNGO</strong> se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizado a dichos datos. </p>
                                <p className="text-white opacity-8 font-size-lg text-justify">El sitio web <strong>GUNGO.CL</strong> cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se transmiten de forma segura y confidencial, al ser esta transmisión entre el servidor y la persona usuaria, y en retroalimentación, totalmente cifrada o encriptada.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Sin embargo, debido a que <strong>GUNGO</strong> no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, la persona responsable del tratamiento se compromete a comunicar a las personas usuarias, sin dilación indebida, la ocurrencia de cualquier violación de la seguridad de los datos personas que sea probable que entrañe un alto riesgo para los derechos y libertades de las personas físicas. Se entiende por violación de la seguridad de los datos personales toda violación de la seguridad que ocasione la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizado a dichos datos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Los datos personales serán tratados como confidenciales por la persona responsable del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligación legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados y toda persona a la cual le haga accesible la información.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">11. Derechos derivados del tratamiento de los datos personales</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">La persona usuaria tiene sobre <strong>GUNGO</strong> y podrá, por tanto, ejercer frente a la persona responsable del tratamiento los siguientes derechos:</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>a. Derecho de acceso: es el derecho que tienen las personas usuarias a obtener confirmación de si <strong>GUNGO</strong> está tratando o no sus datos personales y, en caso afirmativo, obtener información sobre sus datos concretos de carácter personal y del tratamiento que <strong>GUNGO</strong> haya realizado o realice, así como, entre otras, de la información disponible sobre el origen de dichos datos y las personas destinatarias de las comunicaciones realizadas o previstas de los mismos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>b. Derecho de rectificación: es el derecho de las personas usuarias a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>c. Derecho de supresión ("el derecho al olvido"): Es el derecho de las personas usuarias, siempre que la legislación vigente no establezca lo contrario, a obtener la supresión de sus datos personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; cuando la persona usuaria haya retirado su consentimiento al tratamiento y no exista otro motivo legítimo para continuar con el mismo; cuando los datos personales hayan sido tratados ilícitamente; cuando los datos personales deban suprimirse en cumplimiento de una obligación legal.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>Si los datos personales suprimidos o rectificados hubieren sido comunicados previamente a tercera personas determinadas o determinables, la persona responsable del tratamiento deberá avisarles a la brevedad posible la operación efectuada. Si no fuese posible determinar las personas a quienes se les haya comunicado, pondrá un aviso que pueda ser de general conocimiento para quienes usen la información de la base datos.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>No podrá pedirse la rectificación, supresión o bloqueo de datos personales almacenados por mandato legal, fuera de los casos contemplados en la ley respectiva.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>d. Derecho a la limitación del tratamiento: es el derecho de las personas usuarias a limitar el tratamiento de sus datos personales. La persona usuaria tiene derecho a obtener la limitación del tratamiento cuando impugne la exactitud de sus datos personales, el tratamiento sea ilícito, el responsable del tratamiento ya no necesite los datos personales, pero la persona usuaria los necesite para hacer reclamaciones, y cuando la persona usuaria se haya opuesto al tratamiento.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>e. Derecho a la portabilidad de los datos: en caso que el tratamiento se efectúe por medios automatizados, la persona usuaria tendrá derecho a recibir de la persona responsable del tratamiento sus datos personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea técnicamente posible, la persona responsable del tratamiento transmitirá directamente los datos a esa otra responsable.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>f. Derecho de oposición: es el derecho de las persona usuarias a que no se lleve a cabo el tratamiento de sus datos de carácter personal o se cese el tratamiento de los mismos por parte de <strong>GUNGO</strong>.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>g. Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles: es el derecho de las personas usuarias a no ser objeto de una decisión individualizada basada únicamente en el tratamiento automatizado de sus datos personales, incluida la elaboración de perfiles, existente salvo que la legislación vigente establezca lo contrario.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La persona usuaria podrá ejercitar sus derechos mediante comunicación escrita dirigida a la persona responsable del tratamiento, conforme lo establece el artículo 16 de la Ley N° 19.628.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">12. Enlaces a sitios web de terceros</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">El sitio web <strong>GUNGO.CL</strong> puede incluir hipervínculos o enlaces que permitan acceder a páginas web de terceras personas distintas de <strong>GUNGO</strong>. Las personas titulares de dichos sitios web dispondrán de sus propias políticas de privacidad y de protección de datos, siendo ellos, en cada caso, responsables de sus propias bases de datos y de sus propias prácticas de privacidad.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">13. Reclamaciones ante la autoridad de control </Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">En caso de que la persona usuaria considere que existe un problema o infracción de la normativa vigente en la forma en la que se están tratando sus datos personales, tendrá derecho a ejercer las acciones que estime pertinentes ante los Tribunales de Justicia.</p>

                                <Typography variant="h5" className="font-weight-bold text-white mb-3">II. POLÍTICA DE COOKIES</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">El acceso a este sitio web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada persona usuaria -en los distintos dispositivos que pueda utilizar para navegar- para que el servidor recuerde cierta información que posteriormente, y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable y no dañan el dispositivo de navegación.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La información recolectada a través de las cookies puede incluir la fecha y hora de visitas del sitio web, las páginas visionadas, el tiempo que ha estado en el sitio web y los sitios visitados justo antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de teléfono de la persona usuaria o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer información del disco duro de la persona usuaria o robar información personal. La única manera de que la información privada de la persona usuaria forme parte del archivo cookie es que la persona usuaria dé personalmente esa información al servidor.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la utilización de las mismas será necesario el consentimiento de la persona usuaria. Este consentimiento será comunicado, en base a una elección auténtica, ofrecido mediante una afirmación afirmativa y positiva, antes del tratamiento inicial, removible y documentado. </p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">1. Cookies propias</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Son aquellas cookies que son enviadas al ordenador o dispositivo de la persona usuaria y gestionadas exclusivamente por <strong>GUNGO</strong> para el mejor funcionamiento del sitio web. La información que se recaba se emplea para mejorar la calidad del sitio web, su contenido y la experiencia de la persona usuaria. Estas cookies permiten reconocer a la persona usuaria como visitante recurrente del sitio web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">La o las entidades encargadas del suministro de cookies podrán ceder esta información a terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta información para dichas entidades.</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3"> 2. Cookies de redes sociales</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify"><strong>GUNGO</strong> incorpora plugins de redes sociales que permiten acceder a las mismas a partir del sitio web. Por esta razón, las cookies de redes sociales pueden almacenarse en el navegador de la persona usuaria. Las personas titulares de dichas redes sociales disponen de sus propias políticas de protección de datos y de cookies, siendo ellos mismos, en cada caso, responsables de sus propios ficheros o bases de datos y de sus propias prácticas de privacidad. La persona usuaria debe referirse a las mismas para informarse acerca de dichas cookies y, en su caso, del tratamiento de sus datos personales. Únicamente a título informativo se indica a continuación los enlaces de algunas de las redes sociales en los que se pueden consultar dichas políticas de privacidad y/o de cookies:</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://www.facebook.com/policies/cookies</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://twitter.com/es/privacy</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://www.facebook.com/help/instagram/155833707900388</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://www.youtube.com/intl/es/about/policies/#community-guidelines</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://policies.google.com/privacy?hl=es</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://es.linkedin.com/legal/privacy-policy</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://policy.pinterest.com/es/privacy-policy</p>
                                <p className="text-white opacity-8 font-size-lg text-justify" style={{paddingLeft: '30px'}}>https://www.tiktok.com/legal/privacy-policy?lang=es</p>

                                <Typography variant="h6" className="font-weight-bold text-white mb-3">3. Deshabilitar, rechazar y eliminar cookies</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">La persona usuaria puede deshabilitar, rechazar y eliminar las cookies -total o parcialmente- instaladas en su dispositivo mediante la configuración de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari). En este sentido, los procedimientos para rechazar y eliminar cookies pueden diferir de un navegador de internet a otro. En consecuencia, la persona usuaria debe acudir a las instrucciones facilitadas por el propio navegador de internet que esté utilizando. En el supuesto de que rechace el uso de cookies -total o parcialmente- podrá seguir usando el sitio web, aunque podrá tener limitada la utilización de algunas prestaciones del mismo.</p>

                                <Typography variant="h5" className="font-weight-bold text-white mb-3">III. ACEPTACIÓN Y CAMBIO DE ESTA POLÍTICA DE PRIVACIDAD</Typography>
                                <p className="text-white opacity-8 font-size-lg text-justify">Es necesario que la persona usuaria haya leído y esté conforme con las condiciones sobre la protección de datos de carácter personal contenidas en esta política de privacidad y de cookies y que acepte el tratamiento de sus datos personales para que la persona responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del sitio web implica la aceptación de la política de privacidad y de cookies del mismo.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify"><strong>GUNGO</strong> se reserva el derecho a modificar su política de privacidad y de cookies de acuerdo a su propio criterio, o motivado por un cambio legislativo o jurisprudencial. Los cambios o actualizaciones de esta política de privacidad y de cookies serán puestos en conocimiento de la persona usuaria.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Se recomienda a la persona usuaria consultar esta página de forma periódica para estar al tanto de los últimos cambios o actualizaciones.</p>
                                <p className="text-white opacity-8 font-size-lg text-justify">Esta política de privacidad y de cookies fue elaborada el día <span id="span_id_fechaelaboracion">18 de junio de 2021</span> y se encuentra actualizada para adaptarse a la legislación vigente.</p>

                            </Grid>
                        </div>
                    </Container>
                </div>
                <div className="shape-container-top-1 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Privacy