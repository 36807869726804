import React from "react";

import {Card, CardContent, Typography} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'
import {Link} from "react-router-dom";

const ExperienceCard = (props) => {
    return (
        <div>
            <Card className="m-5">
                <CardContent>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="text-warning font-size-lg">
                        <Rating value={props.rating} readOnly />
                            <Link to={`/center/${props.center_slug}`}>
                                <Typography variant="subtitle2" color="primary">{props.center_name}</Typography>
                            </Link>
                        </div>
                        <div>
                            <Link to={`/center/${props.center_slug}`}>
                            <div className="avatar-icon rounded">
                                <img src={props.center_logo} alt="comentario centro buceo" />
                            </div>
                            </Link>
                        </div>
                    </div>
                    <blockquote className="mb-3 mt-2 font-size-lg text-black-50">
                        {!props.comment ? "" : props.comment.substring(0, 150) + "..."}
                    </blockquote>
                    <div className="align-box-row">
                        <div className="avatar-icon-wrapper avatar-icon-lg">
                            <div className="avatar-icon rounded-circle">
                            <img src="https://cdn.gungo.cl/gungo/default/user-avatar.png" alt="review de usuario" />
                            </div>
                        </div>
                        <div className="pl-2">
                            <span className="d-block font-size-lg">
                                {props.user_name}
                                <small className="d-block text-black-50">
                                Nivel {props.user_level} - Gungo ptos.
                                </small>
                            </span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );


};

export default ExperienceCard;