import React from "react";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const LoginLinks = () => {
    return (
        <>
            <Link to="/login">
                <Button size="small" className="btn-transparent btn-link btn-link-primary">
                    Iniciar Sesión
                </Button>
            </Link>
            <Link to="/register">
                <Button size="small" className="btn-transparent btn-link btn-link-primary" >
                    Registrarse
                </Button>
            </Link>
        </>
    );
};

export default LoginLinks;