import React, {useEffect, useState} from "react";
import PageTitle from "../layouts/PageTitle";
import MyTopCenters from "../components/MyTopCenters";
import MyTimeline from "../components/MyTimeline";
import MyDivesChart from "../components/MyDivesChart";

import {updateUser, useAuthDispatch, useAuthState} from "../Context"

import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';

import {
    Container,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    Card,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Snackbar
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";

import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Badges from "../components/Badges";


const UserProfile = () => {

    useEffect(() => {
        document.title = "Perfil de usuario - Gungo"
    }, [])

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleCloseAlertBox = () => {
        setAlertBox((s) => ({ ...s, open: false }))
    }

    const userDetails = useAuthState()
    useEffect(() => {
        setFullNameField(userDetails.user?.full_name)
    }, [userDetails])

    const [alertBox, setAlertBox] = useState({
        open: false,
        message: '',
        type: ''
    })

    const [fullNameField, setFullNameField] = useState('')
    const dispatch = useAuthDispatch()

    const handleClickChangeName = async () => {
        if (fullNameField.length < 3) {
            setAlertBox({
                open: true,
                type: 'error',
                message: 'Tu nombre debe tener mínimo 3 letras'
            })
        } else {
            const payload = {user: {full_name: fullNameField}}
            let response = await updateUser(dispatch, payload)

            setAlertBox({
                open: true,
                type: 'success',
                message: 'Listo! modificamos tu nombre'
            })
            setOpen1(false)
        }
    }

    return (
        <Container fixed maxWidth="lg" className="mt-2">
            <PageTitle
                titleHeading="Perfil de Usuario"
                titleDescription="Aquí puedes editar tus datos de usuario"
                iconHeader="https://cdn.gungo.cl/gungo/default/user-avatar.png">
            </PageTitle>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid item lg={12}>
                    <Card>
                        <div className="p-4">
                            <Grid container spacing={0} alignItems="stretch">
                                <Grid item md={2}>
                                    <div className="d-flex align-items-center">
                                        <div className="mx-auto py-2">
                                            <CircularProgressbarWithChildren
                                                value={userDetails.user.progress}
                                                strokeWidth={6}
                                                className="circular-progress-success">
                                                <div className="avatar-icon-wrapper avatar-initials avatar-icon-xl p-4">
                                                    <div className="avatar-icon circle text-white bg-dark">
                                                        <img src="https://cdn.gungo.cl/gungo/default/user-avatar.png" alt="avatar" />
                                                    </div>
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={10} className="d-flex MuiGrid-justify-xs-space-between">
                                    <div>
                                        <a
                                            href="#/"
                                            onClick={(e) => e.preventDefault()}
                                            className="font-size-lg mx-0 my-3 my-xl-0 font-weight-bold p-0">
                                            {userDetails.user.full_name}
                                        </a>
                                        <div className="d-block d-md-flex align-items-center mt-1 mb-3">
                                            <EmailTwoToneIcon />
                                            <span className="text-black-50 pr-4">{userDetails.user.email}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            size="small"
                                            onClick={handleClickOpen1}
                                            className="btn-neutral-primary text-uppercase font-size-xs font-weight-bold">
                                            Editar
                                        </Button>
                                        <Dialog
                                            classes={{ paper: 'modal-content rounded-lg' }}
                                            open={open1}
                                            onClose={handleClose1}
                                            aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Editar Usuario</DialogTitle>
                                            <DialogContent className="p-4">
                                                <DialogContentText>
                                                    Puedes cambiar como se muestra tu nombre de usuario.
                                                </DialogContentText>
                                                <DialogContentText className="mb-0">
                                                    <TextField
                                                        id="email"
                                                        label="Email"
                                                        defaultValue={userDetails.user.email}
                                                        InputProps={{
                                                            readOnly: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        className="m-2"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        className="m-2"
                                                        id="full_name"
                                                        label="Nombre"
                                                        variant="outlined"
                                                        defaultValue={fullNameField}
                                                        onChange={(e) => setFullNameField(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions className="p-4">
                                                <Button
                                                    onClick={handleClose1}
                                                    variant="text"
                                                    className="mr-3">
                                                    Cancelar
                                                </Button>
                                                <Button onClick={handleClickChangeName} className="btn-primary shadow-none">
                                                    Guardar
                                                </Button>

                                            </DialogActions>
                                        </Dialog>
                                        <Snackbar open={alertBox.open} autoHideDuration={6000} onClose={handleCloseAlertBox} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                            <MuiAlert onClose={handleCloseAlertBox} severity={alertBox.type}>
                                                {alertBox.message}
                                            </MuiAlert>
                                        </Snackbar>
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreTwoToneIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                            className="bg-light"
                                        >
                                            <div>
                                                <span>Eres <strong>Nivel {userDetails.user.level}</strong> en Gungo. Dale una mirada a tus logros aquí</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Badges userDetails={userDetails} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
                <Grid item lg={6}>
                    <MyTimeline />
                </Grid>
                <Grid item lg={6} >
                    <MyDivesChart />
                </Grid>
                <Grid item lg={12}>
                    <MyTopCenters />
                </Grid>
                <Grid item lg={12}>
                    {/*ToDo: agregar reviews hechos por el usuario*/}
                </Grid>
            </Grid>
        </Container>
    );
}

export default UserProfile;