import React, {useContext, useEffect, useState} from "react";
import { Grid, CardContent } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import {fetchAPI} from "../../api";
import {ReviewsContext} from "../../Context";

import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@material-ui/icons/ThumbDownTwoTone';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';

const CenterStatistics = (props) => {

    const [statistics, setStatistics] = useState({
        rating_avg: 0,
        rating_count: 0,
        checkins_positive: 0,
        checkins_negative: 0
    })
    const [reviewsContext, setReviewsContext] = useContext(ReviewsContext)

    useEffect(() => {
        if (props.center_slug) {
            fetchAPI('GET', `/centers/${props.center_slug}/statistics`, '', false)
            .then(data => {
                setStatistics(data)
            })
        }
    }, [props.center_slug, reviewsContext])

    return (
            <div className="bg-plum-plate p-3 rounded my-4">
                <Grid container spacing={6}>
                    <Grid item md={4}>
                        <HashLink
                            to="#reviewsAnchor"
                            smooth
                            className="card bg-white card-box card-box-border-bottom border-warning card-box-hover-rise-alt">
                            <CardContent>
                                <div className="align-box-row">
                                    <div className="text-left">
                                        <div className="d-40 rounded-circle bg-warning text-white btn-icon mx-auto text-center shadow-warning">
                                            <StarTwoToneIcon />
                                        </div>
                                        <div className="mt-2 line-height-sm">
                                            <b className="font-size-lg text-black pr-1">{statistics.rating_avg}</b>
                                            <span className="text-black-50 ml-3">{statistics.rating_count} reseñas</span>
                                        </div>
                                    </div>
                                    <div className="ml-auto card-hover-indicator">
                                        <ChevronRightTwoToneIcon />
                                    </div>
                                </div>
                            </CardContent>
                        </HashLink>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card bg-white card-box card-box-border-bottom border-success card-box-hover-rise-alt">
                            <CardContent>
                                <div>
                                    <div className="text-left">
                                        <div className="d-40 rounded-circle bg-success text-white btn-icon mx-auto text-center shadow-success">
                                            <ThumbUpTwoToneIcon />
                                        </div>
                                        <div className="mt-2 line-height-sm">
                                            <b className="font-size-lg text-black pr-1">{statistics.checkins_positive}</b>
                                            <span className="text-black-50 ml-3">Exp. Positivas</span>
                                        </div>
                                    </div>

                                </div>
                            </CardContent>
                        </a>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card bg-white card-box card-box-border-bottom border-danger card-box-hover-rise-alt">
                            <CardContent>
                                <div>
                                    <div className="text-left">
                                        <div className="d-40 rounded-circle bg-danger text-white btn-icon mx-auto text-center shadow-danger">
                                            <ThumbDownTwoToneIcon />
                                        </div>
                                        <div className="mt-2 line-height-sm">
                                            <b className="font-size-lg text-black pr-1">{statistics.checkins_negative}</b>
                                            <span className="text-black-50 ml-3">Exp. Negativas</span>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </a>
                    </Grid>
                </Grid>
            </div>
    );
}

export default CenterStatistics;