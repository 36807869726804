const ROOT_URL = process.env.REACT_APP_API;
const TOKEN_EXPIRATION_TIME = 12 * 60 * 60 * 1000; //12 hrs

export async function loginUser(dispatch, loginPayload) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${ROOT_URL}/login`, requestOptions);
        let data = await response.json();

        const token = response.headers.get('Authorization');
        if (token) {
            data.auth_token = token.split(' ')[1]
            dispatch({ type: 'LOGIN_SUCCESS', payload: data });
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem('expiration', Date.now() + TOKEN_EXPIRATION_TIME);
            return data;
        }

        dispatch({ type: 'LOGIN_ERROR', error: data.error });
        return;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
        console.log(error);
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expiration');
}

export async function updateUser(dispatch, updatePayload) {
    let token = JSON.parse(localStorage.getItem('currentUser')).auth_token;
    token = 'Bearer ' + token

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(updatePayload),
    };

    try {
        let response = await fetch(`${ROOT_URL}/users/me`, requestOptions);
        let data = await response.json()

        let dataPayload = data.user_profile

        dataPayload.auth_token = JSON.parse(localStorage.getItem('currentUser')).auth_token
        dispatch({type: 'UPDATE', payload: dataPayload})
        localStorage.setItem('currentUser', JSON.stringify(dataPayload))

        return data;

    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
        console.log(error);
    }
}

export async function reloadUser(dispatch) {
    let token = JSON.parse(localStorage.getItem('currentUser')).auth_token;
    token = 'Bearer ' + token

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };

    try {
        let response = await fetch(`${ROOT_URL}/users/me`, requestOptions)
        let data = await response.json()

        data = data.user_profile
        data.auth_token = JSON.parse(localStorage.getItem('currentUser')).auth_token
        dispatch({type: 'RELOAD', payload: data})
        localStorage.setItem('currentUser', JSON.stringify(data))
        return data;

    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
        console.log(error);
    }
}