import Login from '../pages/Login';
import Home from '../pages/Home';
import UserProfile from "../pages/UserProfile";
import Centers from "../pages/Centers";
import Center from "../pages/Center";
import Contact from "../pages/Contact";
import FormAddNewCenter from "../pages/FormAddNewCenter";
import AboutGungo from "../pages/AboutGungo";
import AboutUs from "../pages/AboutUs";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import Thanks from "../pages/Thanks";
import RecoverPassword from "../pages/RecoverPassword";
import NewPassword from "../pages/NewPassword";
import Register from "../pages/Register";
import HowTo from '../pages/HowTo';

const routes = [
    {
        path: '/home',
        component: Home,
        isPrivate: false,
    },
    {
        path: '/login',
        component: Login,
        isPrivate: false,
    },
    {
        path: '/register',
        component: Register,
        isPrivate: false,
    },
    {
        path: '/recoverpassword',
        component: RecoverPassword,
        isPrivate: false,
    },
    {
        path: '/new_password',
        component: NewPassword,
        isPrivate: false,
    },
    {
        path: '/contact',
        component: Contact,
        isPrivate: false
    },
    {
        path: '/about-gungo',
        component: AboutGungo,
        isPrivate: false
    },
    {
        path: '/about-us',
        component: AboutUs,
        isPrivate: false
    },
    {
        path: '/privacy',
        component: Privacy,
        isPrivate: false
    },
    {
        path: '/terms',
        component: Terms,
        isPrivate: false
    },
    {
        path: '/thanks',
        component: Thanks,
        isPrivate: false
    },
    {
        path: '/newcenter',
        component: FormAddNewCenter,
        isPrivate: false
    },
    {
        path: '/centers',
        component: Centers,
        isPrivate: false,
    },
    {
        path: '/center/:slug',
        component: Center,
        isPrivate: false,
    },
    {
        path: '/profile',
        component: UserProfile,
        isPrivate: true,
    },
    {
        path: '/howto',
        component: HowTo,
        isPrivate: false,
    }
];

export default routes;