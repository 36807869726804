import React, {useEffect, useState} from "react";
import {
    Card,
    List,
    Container,
    Divider
} from "@material-ui/core";
import {fetchAPI} from "../../api";

import CenterTopDiver from "./CenterTopDiver";

const CenterTopDiverList = (props) => {
    const [tops, setTops] = useState([])
    useEffect(() => {
        if (props.slug) {
            fetchAPI('GET', `/centers/${props.slug}/checkins/topdivers`, '', false)
                .then(data => {
                    setTops(data)
                })
        }
    },[props.slug])

    return (
        <Container className="p-2">
            <Divider className="mb-4" />
            <h2>Los Top 5 en {props.name}</h2>
        <Card className="card-box">
            <div className="card-header">
                <div className="card-header--title">
                    <small className="d-block text-uppercase mt-1">
                        Buzos destacados
                    </small>
                    <b>Los Top 5 en {props.name}</b>
                </div>
            </div>
            <List component="div" className="list-group-flush">
                {tops.map((t, i) => (
                    <CenterTopDiver key={t.id} {...t} index={i}  />
                ))}
            </List>
        </Card>
        </Container>
    );
}

export default CenterTopDiverList;