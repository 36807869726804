import React, {useEffect} from "react"
import {Container, Grid, Typography} from "@material-ui/core";

import hero from  '../assets/images/hero-bg/hero-2.jpg'

const Thanks = () => {

    useEffect(() => {
        document.title = "Agradecimientos - Gungo"
    }, [])

    return (
        <div
            className="hero-wrapper bg-composed-wrapper bg-second z-below">
            <div className="hero-wrapper--content flex-column">
                <div className="shape-container-bottom-2 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
                    </svg>
                </div>
                <div
                    className="bg-composed-wrapper--image opacity-3"
                    style={{ backgroundImage: 'url(' + hero + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-night-sky opacity-4" />
                <div className="bg-composed-wrapper--content pb-5">
                    <Container fixed maxWidth="md" className="py-4">
                        <div className="py-5 py-sm-0 text-white opacity-8 font-size-lg text-justify">
                            <Typography variant="h4" className="font-weight-bold mb-3">Muchas Gracias a quienes nos ayudan</Typography>
                            <Grid item xs={12}>
                                <Typography variant="h5" className="font-weight-bold mb-3">Muchas gracias a todos quienes nos ayudaron de una u otra forma a construir esta nueva idea</Typography>

                                <Typography variant="h6" className="font-weight-bold mb-3">Fotos utilizadas en el diseño del sitio</Typography>

                                <p style={{paddingLeft: '30px'}}>
                                    Fotos de <a target="_blank" rel="noreferrer" className="text-white" href="https://unsplash.com/@nottpeera?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nott Peera</a> en <a className="text-white" href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                </p>
                                <p style={{paddingLeft: '30px'}}>
                                    Fotos de <a target="_blank" rel="noreferrer" className="text-white" href="https://unsplash.com/@hellotonytheturtle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Amanda Panda</a> en <a className="text-white" href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                </p>
                                <p style={{paddingLeft: '30px'}}>
                                    Fotos de <a target="_blank" rel="noreferrer" className="text-white" href="https://unsplash.com/@mima_photography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Miranda Salzgeber</a> en <a className="text-white" href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                </p>
                                <p style={{paddingLeft: '30px'}}>
                                    Fotos de <a target="_blank" rel="noreferrer" className="text-white" href="https://unsplash.com/@by_ners?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Neyerlin Silva</a> en <a className="text-white" href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                </p>
                                <p style={{paddingLeft: '30px'}}>
                                    Fotos de <a target="_blank" rel="noreferrer" className="text-white" href="https://unsplash.com/@yaoyaosam?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sam Deng</a> en <a className="text-white" href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                                </p>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <div className="shape-container-top-1 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Thanks