import React, { useState } from 'react';
import BubbleChartTwoToneIcon from '@material-ui/icons/BubbleChartTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import {
    Typography,
    Badge,
    Menu,
    Button,
    List,
    ListItem,
    Divider
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { useAuthDispatch, logout, useAuthState } from "../../Context";
import CheckIn from "../../components/CheckIn";

const StyledBadge = withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge);

function HeaderUserbox(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();

    let history = useHistory();
    const handleSignOut = () => {
        logout(dispatch);
        setAnchorEl(null);
        history.push('/login');
    };

    return (
        <>
            <Button
                variant="text"
                onClick={handleClick}
                className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
                disableRipple>
                <div className="d-block p-0 avatar-icon-wrapper">
                    <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        badgeContent=" "
                        classes={{ badge: 'bg-success badge-circle border-0' }}
                        variant="dot">
                        <div className="avatar-icon rounded">
                            <img src="https://cdn.gungo.cl/gungo/default/user-avatar.png" alt="avatar" />
                        </div>
                    </StyledBadge>
                </div>

                <div className="d-none d-sm-block pl-2">
                    <div className="font-weight-bold pt-2 line-height-1">{userDetails.user?.full_name || ''}</div>
                    <span className="text-black-50">Nivel {userDetails.user?.level || '0'}</span>
                </div>
                <span className="pl-1 pl-xl-3">
                    <ExpandMoreTwoToneIcon />
                </span>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={Boolean(anchorEl)}
                classes={{ list: 'p-0' }}
                onClose={handleClose}>
                <div className="dropdown-menu-lg overflow-hidden p-0">
                    <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                        <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
                            <span>{userDetails.user?.full_name || ''}</span>
                        </Typography>

                    </div>
                    <List
                        component="div"
                        className="nav-neutral-primary text-left d-block px-3 pb-3">
                        <ListItem
                            button
                            component="a"
                            href="/profile"
                            className="d-block text-left">
                            Mi Cuenta
                        </ListItem>
                        <ListItem
                            button
                            onClick={handleSignOut}
                            className="d-block text-left">
                            Salir
                        </ListItem>
                    </List>
                    <Divider className="w-100" />
                    <div className="d-flex py-3 justify-content-center">
                        <div className="d-flex align-items-center">
                            <div className="text-info">
                                <BubbleChartTwoToneIcon />
                            </div>
                            <div className="pl-3 line-height-sm">
                                <b className="font-size-lg">{userDetails.user?.checkins || '0'}</b>
                                <span className="text-black-50 d-block">Buceos</span>
                            </div>
                        </div>
                    </div>
                    <Divider className="w-100" />
                    <div className="d-block rounded-bottom py-3 text-center">
                        <CheckIn />
                    </div>

                {/*    <Divider className="w-100" />*/}
                {/*    <div className="d-block rounded-bottom py-3 text-center">*/}
                {/*        <Tooltip arrow title="Facebook">*/}
                {/*            <Button*/}
                {/*                size="large"*/}
                {/*                className="btn-facebook p-0 d-40 font-size-lg text-white">*/}
                {/*<span className="btn-wrapper--icon">*/}
                {/*  <FontAwesomeIcon icon={['fab', 'facebook']} />*/}
                {/*</span>*/}
                {/*            </Button>*/}
                {/*        </Tooltip>*/}
                {/*        <Tooltip arrow title="Dribbble">*/}
                {/*            <Button*/}
                {/*                size="large"*/}
                {/*                className="btn-dribbble p-0 d-40 font-size-lg text-white mx-2">*/}
                {/*<span className="btn-wrapper--icon">*/}
                {/*  <FontAwesomeIcon icon={['fab', 'dribbble']} />*/}
                {/*</span>*/}
                {/*            </Button>*/}
                {/*        </Tooltip>*/}
                {/*        <Tooltip arrow title="Twitter">*/}
                {/*            <Button*/}
                {/*                size="large"*/}
                {/*                className="btn-twitter p-0 d-40 font-size-lg text-white">*/}
                {/*<span className="btn-wrapper--icon">*/}
                {/*  <FontAwesomeIcon icon={['fab', 'twitter']} />*/}
                {/*</span>*/}
                {/*            </Button>*/}
                {/*        </Tooltip>*/}
                {/*    </div>*/}
                </div>
            </Menu>
        </>
    );
}

export default HeaderUserbox;
