import React, {useContext, useEffect, useState} from "react";
import { Grid, Card, LinearProgress, Container } from "@material-ui/core";
import {fetchAPI} from "../../api";

import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';

import {ReviewsContext} from "../../Context";
import CenterAddReview from "./CenterAddReview";
import CenterReviewList from "./CenterReviewList";
import CenterReviewCard from "./CenterReviewCard";

const CenterReviews = (props) => {

    const [reviewsContext, setReviewsContext] = useContext(ReviewsContext)
    const [statisticsReviews, setStatisticsReviews] = useState({
        rating_count: 0,
        avg_rating: 0,
        avg_safety: 0,
        avg_staff: 0,
        avg_equipment: 0,
        avg_infrastructure: 0
    })
    const [lastReviews, setLastReviews] = useState([])

    useEffect(() => {
        if (props.center_slug) {

            fetchAPI('GET', `/centers/${props.center_slug}/reviews`, '', false)
            .then(response => {
                setReviewsContext(response)
            })
        }
        
    }, [props.center_slug])

    useEffect(() => {
        if (props.center_slug) {
            setLastReviews(reviewsContext.slice(0,3))
            fetchAPI('GET', `/centers/${props.center_slug}/reviews/statistics`, '', false)
                .then(data => {
                    setStatisticsReviews(data)
                })
        }
    },[reviewsContext, props.center_slug])

    return (
            <div id="reviewsAnchor" className="rounded bg-royal py-2 mb-spacing-6-x2">
                <Container className="pt-5">
                    <Grid container spacing={4}>
                        <Grid item lg={4}>
                            <div className=" pb-5 pb-xl-0">
                                <h2 className="text-center display-2 text-white mb-3 font-weight-bold">
                                    Reseñas
                                </h2>
                                <p className="font-size-xl mb-4 text-white-50 text-center">
                                    <span className="text-warning"><StarTwoToneIcon /></span>
                                    <strong>{statisticsReviews.avg_rating}</strong> ({statisticsReviews.rating_count} reseñas)
                                </p>
                                <div className="text-center mb-4">
                                    <CenterAddReview center_slug={props.center_slug} reviews={reviewsContext} />
                                </div>
                                <Card className="p-3">
                                    <div className="text-black-50 pt-2">
                                        Equipamiento
                                    </div>
                                    <div className="align-box-row">
                                        <div className="flex-grow-1">
                                            <LinearProgress variant="determinate" className="progress-animated-alt progress-bar-rounded progress-sm progress-bar-primary" value={(statisticsReviews.avg_equipment*100)/5} />
                                        </div>
                                        <div className="font-size-xl font-weight-bold pl-2">{statisticsReviews.avg_equipment}</div>
                                    </div>

                                    <div className="divider mt-2" />
                                    <div className="text-black-50 pt-2">
                                        Personal
                                    </div>
                                    <div className="align-box-row">
                                        <div className="flex-grow-1">
                                            <LinearProgress variant="determinate" className="progress-animated-alt progress-bar-rounded progress-sm progress-bar-primary" value={(statisticsReviews.avg_staff*100)/5} />
                                        </div>
                                        <div className="font-size-xl font-weight-bold pl-2">{statisticsReviews.avg_staff}</div>
                                    </div>

                                    <div className="divider mt-2" />
                                    <div className="text-black-50 pt-2">
                                        Infraestructura
                                    </div>
                                    <div className="align-box-row">
                                        <div className="flex-grow-1">
                                            <LinearProgress variant="determinate" className="progress-animated-alt progress-bar-rounded progress-sm progress-bar-primary" value={(statisticsReviews.avg_infrastructure*100)/5} />
                                        </div>
                                        <div className="font-size-xl font-weight-bold pl-2">{statisticsReviews.avg_infrastructure}</div>
                                    </div>

                                    <div className="divider mt-2" />
                                    <div className="text-black-50 pt-2">
                                        Seguridad
                                    </div>
                                    <div className="align-box-row">
                                        <div className="flex-grow-1">
                                            <LinearProgress variant="determinate" className="progress-animated-alt progress-bar-rounded progress-sm progress-bar-primary" value={(statisticsReviews.avg_safety*100)/5} />
                                        </div>
                                        <div className="font-size-xl font-weight-bold pl-2">{statisticsReviews.avg_safety}</div>
                                    </div>

                                </Card>
                            </div>
                        </Grid>
                        <Grid item lg={8}>
                            <div className="pl-0 pl-xl-5">
                            {lastReviews.map((r) => (
                                    <CenterReviewCard key={r.id} {...r}  />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xl={12}  >
                            <div className="d-flex justify-content-end align-items-center font-size-md">
                                <CenterReviewList reviews={reviewsContext}  />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
    );
}

export default CenterReviews;