import React, {useState} from "react";
import {Button, Dialog, DialogContent} from "@material-ui/core";

import CenterReviewCard from "./CenterReviewCard";

export default function CenterReviewList(props) {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Button
                onClick={handleClickOpen}
                className="btn-first mb-2"
                disabled={!(!!props.reviews.length)}
                size="large">
                {(props.reviews.length > 0) ? `ver ${props.reviews.length} reseñas` : 'Sin reseñas'}
            </Button>
            <Dialog
                classes={{ paper: 'modal-content rounded-lg bg-royal' }}
                maxWidth="xl"
                open={open}
                onClose={handleClose}

                aria-labelledby="review-modal-form">
                <DialogContent className="p-0">
                    <div className="p-4">
                        {props.reviews.map((r) => (
                            <CenterReviewCard key={r.id} {...r} extend={true}  />
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}