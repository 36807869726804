import React from "react";

import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import LocalParkingTwoToneIcon from '@material-ui/icons/LocalParkingTwoTone';
import BathtubTwoToneIcon from '@material-ui/icons/BathtubTwoTone';
import WifiTwoToneIcon from '@material-ui/icons/WifiTwoTone';
import RestaurantTwoToneIcon from '@material-ui/icons/RestaurantTwoTone';
import WcTwoToneIcon from '@material-ui/icons/WcTwoTone';
import DirectionsBoatTwoToneIcon from '@material-ui/icons/DirectionsBoatTwoTone';
import PoolTwoToneIcon from '@material-ui/icons/PoolTwoTone';
import FreeBreakfastTwoToneIcon from '@material-ui/icons/FreeBreakfastTwoTone';
import HotelTwoToneIcon from '@material-ui/icons/HotelTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import FastfoodTwoToneIcon from '@material-ui/icons/FastfoodTwoTone';
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';

const facilityIcon = {
    defaulticon: CheckCircleTwoToneIcon,
    duchas: BathtubTwoToneIcon,
    banos: WcTwoToneIcon,
    embarcacion: DirectionsBoatTwoToneIcon,
    piscina: PoolTwoToneIcon,
    snack: FastfoodTwoToneIcon,
    hospedaje: HotelTwoToneIcon,
    lockers: LockTwoToneIcon,
    estacionamiento: LocalParkingTwoToneIcon,
    cafeteria: FreeBreakfastTwoToneIcon,
    camarines: WcTwoToneIcon,
    nitrox: CheckCircleTwoToneIcon,
    cursos: SchoolTwoToneIcon,
    ingles: TranslateTwoToneIcon,
    wifi: WifiTwoToneIcon,
    almuerzo: RestaurantTwoToneIcon
}

const CenterFacility = (props) => {
    const TagIcon = facilityIcon[props.slug] || CheckCircleTwoToneIcon;

    return (
        <div
            className="m-2 btn-input-facilities">
                        <span className="d-30 text-white d-flex align-items-center justify-content-center rounded-pill bg-first mr-2">
                            <TagIcon />
                        </span>
            <span className="font-weight-bold mt-2">{props.name}</span>
        </div>
    )
}

export default CenterFacility