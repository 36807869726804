import React, {useEffect, useState} from 'react';

import { Grid, InputAdornment, Button, TextField } from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import {fetchAPI} from "../api";

const RecoverPassword = () => {

    useEffect(() => {
        document.title = "Recuperar Contraseña - Gungo"
    }, [])

    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const handleSend = () => {
        const value = { user: { email: email } }
        fetchAPI('POST', `/password`, value, false)
            .then(data => {
                setSent(true)
            })
    };

    return (
        <div className="app-wrapper bg-white min-vh-100">
            <div className="app-main min-vh-100">
                <div className="app-content p-0">
                    <div className="app-content--inner d-flex align-items-center">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                            <div className="bg-composed-wrapper--content py-5">
                                <Grid
                                    container
                                    item
                                    md={10}
                                    lg={8}
                                    xl={4}
                                    className="mx-auto"
                                    spacing={2}>
                                    <Grid item xs={12} className="text-center">
                                        <h1 className="display-4 mb-1 font-weight-bold">
                                            Recuperar contraseña
                                        </h1>
                                        <p className="font-size-lg mb-0 text-black-50">
                                            {sent
                                                ? 'Se te envió un correo con las instrucciones para recuperar tu contraseña en la dirección proporcionada'
                                                : 'Escribe tu correo electronico'}
                                        </p>
                                    </Grid>
                                    {!sent && (
                                        <>
                                            <Grid item xs={12} className="text-center">
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="textfield-email"
                                                        label="Correo electrónico"
                                                        value={email}
                                                        type="email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MailOutlineTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="text-center mb-5">
                                                    <Button
                                                        fullWidth
                                                        onClick={handleSend}
                                                        className="btn-second font-weight-bold my-2 py-3">
                                                        Recuperar Contraseña
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </>
                                    )}
                                    {sent && (
                                        <Grid item xs={12} alignContent="center">
                                            <div className="text-center mb-5">
                                                <a
                                                    href="/"
                                                    className="text-first mt-4 font-size-xl text-black">
                                                    Volver a Gungo
                                                </a>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoverPassword;
