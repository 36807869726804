import React, {useEffect, useState} from "react";
import {
    Card,
    List
} from "@material-ui/core";

import {fetchAPI} from "../api";
import MyTopCenterRow from "./MyTopCenterRow";
import {useAuthState} from "../Context";

const MyTopCenters = () => {
    const userDetails = useAuthState()
    const [topCenters, setTopCenters] = useState([]);
    const [maxCheckIns, setMaxCheckIns] = useState(0)

    useEffect(() => {
        fetchAPI('GET', '/users/me/topcenters', '', true)
            .then(data => {
                setTopCenters(data)
                const max = data.reduce((acc, checkIn) => acc = acc > checkIn.total ? acc : checkIn.total, 0)
                setMaxCheckIns(max)
            })
    }, [userDetails]);

    return (
        <Card className="card-box">
            <div className="card-header">
                <div className="card-header--title">
                    <small className="d-block text-uppercase mt-1">
                        Centros
                    </small>
                    <b>Mis Centros Favoritos</b>
                </div>
            </div>
            <List component="div" className="list-group-flush">
                {topCenters.map((center) => (
                    <MyTopCenterRow key={center.id} {...center} max={maxCheckIns} />
                ))}
            </List>
        </Card>
    );
}

export default MyTopCenters;