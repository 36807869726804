import React, {useEffect} from "react"
import {Container, Grid, Typography, Card, CardContent, Button} from "@material-ui/core";
import FormatQuoteTwoToneIcon from '@material-ui/icons/FormatQuoteTwoTone';
import CallMadeTwoToneIcon from '@material-ui/icons/CallMadeTwoTone';

import { Link } from "react-router-dom"

import hero from  '../assets/images/hero-bg/hero-2.jpg'

const AboutUs = () => {
    useEffect(() => {
        document.title = "Quienes están detras de Gungo"
    }, [])
    return (
        <div
            className="hero-wrapper bg-composed-wrapper bg-second z-below">
            <div className="hero-wrapper--content flex-column">
                <div className="shape-container-bottom-2 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,128L24,144C48,160,96,192,144,213.3C192,235,240,245,288,229.3C336,213,384,171,432,160C480,149,528,171,576,176C624,181,672,171,720,138.7C768,107,816,53,864,69.3C912,85,960,171,1008,218.7C1056,267,1104,277,1152,240C1200,203,1248,117,1296,96C1344,75,1392,117,1416,138.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path>
                    </svg>
                </div>
                <div
                    className="bg-composed-wrapper--image opacity-3"
                    style={{ backgroundImage: 'url(' + hero + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-night-sky opacity-4" />
                <div className="bg-composed-wrapper--content pb-5">
                    <Container fixed maxWidth="md" className="py-4">
                        <div className="py-5 py-sm-0">
                            <Typography variant="h4" className="font-weight-bold text-white mb-3">Detras de gungo.cl estamos nosotros</Typography>
                            <Grid item xs={12}>
                                <p className="text-white opacity-8 font-size-lg text-justify">
                                    Somos 2 hermanos que amamos el buceo. Con años de experiencia en e-commerce y sistemas web, queremos traer esa experiencia a gungo.cl.
                                </p>
                                <p className="text-white opacity-8 font-size-lg text-justify">
                                    Esperamos que esta plataforma se transforme en una gran comunidad que reúna a los buzos de Chile y nos ayude a difundir las bellezas del mar.
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Card className="m-4 mt-5">
                                    <CardContent>
                                        <div className="align-box-row align-items-start">
                                            <div>
                                                <Card className="card-transparent">
                                                    <img
                                                        src="https://cdn.gungo.cl/gungo/default/user-avatar.png"
                                                        className="card-img-top rounded-circle"
                                                        style={{ width: 80 }}
                                                        alt="Alvaro"
                                                    />
                                                </Card>
                                            </div>
                                            <div className="pl-4">
                                                <blockquote className="my-3 text-black-50">
                                                    Espero que gungo le sirva a todos para descubrir el Chile submarino y conocer gente bakan.
                                                </blockquote>
                                                <div className="font-size-lg font-weight-bold">
                                                    Alvaro
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <FormatQuoteTwoToneIcon />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card className="m-4 mt-5">
                                    <CardContent>
                                        <div className="align-box-row align-items-start">
                                            <div>
                                                <Card className="card-transparent">
                                                    <img
                                                        src="https://cdn.gungo.cl/gungo/default/user-avatar.png"
                                                        className="card-img-top rounded-circle"
                                                        style={{ width: 80 }}
                                                        alt="Alvaro"
                                                    />
                                                </Card>
                                            </div>
                                            <div className="pl-4">
                                                <blockquote className="my-3 text-black-50">
                                                    Siempre quise un lugar donde poder buscar mi próximo destino de buceo en Chile, así que como no existía... lo creamos nosotros.
                                                </blockquote>
                                                <div className="font-size-lg font-weight-bold">
                                                    Cristian
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <FormatQuoteTwoToneIcon />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} className="mt-5">
                                <Link to="/thanks" >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="info"
                                        startIcon={<CallMadeTwoToneIcon />}
                                        endIcon={<CallMadeTwoToneIcon />}
                                        className="btn-info px-5 font-size-lg shadow-sm-dark py-3 mx-2"
                                    >
                                        Conoce a todos quienes nos ayudan
                                    </Button>
                                </Link>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <div className="shape-container-top-1 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,256L24,224C48,192,96,128,144,133.3C192,139,240,213,288,218.7C336,224,384,160,432,138.7C480,117,528,139,576,138.7C624,139,672,117,720,138.7C768,160,816,224,864,250.7C912,277,960,267,1008,229.3C1056,192,1104,128,1152,138.7C1200,149,1248,235,1296,250.7C1344,267,1392,213,1416,186.7L1440,160L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default AboutUs