import React from "react";
import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";
import {Button, Dialog} from "@material-ui/core";
import {Link} from "react-router-dom";

const LoginWarning = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            classes={{ paper: 'shadow-sm-dark rounded-sm' }}>
            <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
                        <PersonAddTwoToneIcon />
                    </div>
                </div>
                <h4 className="font-weight-bold mt-4">Debes ser usuario registrado</h4>
                <p className="mb-0 text-black-50">
                    {props.message}
                </p>
                <div className="pt-4">
                    <Button
                        onClick={props.handleClose}
                        className="btn-neutral-secondary text-danger mx-1">
                        <span className="btn-wrapper--label">Cerrar</span>
                    </Button>
                    <Link to="/login">
                        <Button onClick={props.handleClose} className="btn-first mx-1">
                            <span className="btn-wrapper--label">Ingresa a Gungo</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </Dialog>
    )
}
export default LoginWarning