import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, List, ListItem } from '@material-ui/core';
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <Container maxWidth={false} className="mt-4 d-flex bg-vicious-stance font-size-sm">
            <Container className="pt-5">
                    <Grid container spacing={0} className="text-center w-100">
                        <Grid item sm={6} md={3}>
                            <div className="my-4 my-xl-0">
                                <h6 className="text-white font-weight-bold mb-3 text-uppercase">
                                    Con&oacute;cenos
                                </h6>
                                <List
                                    component="div"
                                    className="nav-transparent-alt flex-column justify-content-center">
                                    <Link to="/about-gungo">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Gungo
                                        </ListItem>
                                    </Link>
                                    <Link to="/about-us">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Qui&eacute;nes Somos
                                        </ListItem>
                                    </Link>
                                    <Link to="/thanks">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Agradecimientos
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </Grid>
                        <Grid item sm={6} md={3}>
                            <div className="my-4 my-xl-0">
                                <h6 className="text-white font-weight-bold mb-3 text-uppercase">
                                    Necesitas Ayuda?
                                </h6>
                                <List
                                    component="div"
                                    className="nav-transparent-alt flex-column justify-content-center">
                                    <Link to="/contact">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Contactanos
                                        </ListItem>
                                    </Link>
                                    <Link to="/newcenter">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Agregar un Nuevo Centro
                                        </ListItem>
                                    </Link>
                                    <Link to="/contact">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Reportar un Problema
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </Grid>
                        <Grid item sm={6} md={3}>
                            <div className="my-4 my-xl-0">
                                <h6 className="text-white font-weight-bold mb-3 text-uppercase">
                                    Destinos de Buceo
                                </h6>
                                <List
                                    component="div"
                                    className="nav-transparent-alt flex-column justify-content-center">
                                    <Link to="/centers?z=norte">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Bucea en el Norte de Chile
                                        </ListItem>
                                    </Link>
                                    <Link to="/centers?z=centro">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Bucea en la Zona Central
                                        </ListItem>
                                    </Link>
                                    <Link to="/centers?z=sur">
                                        <ListItem
                                            component="span"
                                            button
                                            className="px-0 py-1 d-block text-white-50 text-center">
                                            Bucea en el Sur de Chile
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </Grid>
                        <Grid item sm={6} md={3}>
                            <div className="my-4 my-xl-0">
                                <h6 className="text-white font-weight-bold mb-3 text-uppercase pl-2">
                                    Mantente Conectado
                                </h6>
                                <p className="text-white-50">
                                    Mantente al día con las últimas novedades del buceo en Chile
                                </p>
                                <List
                                    component="div"
                                    className="nav-transparent text-nowrap d-flex justify-content-center">
                                    <ListItem
                                        button
                                        className="text-white-50">
                                        <FontAwesomeIcon
                                            icon={['fab', 'facebook']}
                                            className="font-size-lg"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className="text-white-50">
                                        <FontAwesomeIcon
                                            icon={['fab', 'twitter']}
                                            className="font-size-lg"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className="text-white-50">
                                        <FontAwesomeIcon
                                            icon={['fab', 'google']}
                                            className="font-size-lg"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className="text-white-50">
                                        <FontAwesomeIcon
                                            icon={['fab', 'instagram']}
                                            className="font-size-lg"
                                        />
                                    </ListItem>
                                </List>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="divider mb-2 mt-2 bg-white opacity-2" />
                    <div className="py-3 d-block d-lg-flex font-size-xs justify-content-between">
                        <div className="text-center d-block mb-3 mb-md-0 text-white">
                            Copyright &copy; 2021 - gungo.cl
                        </div>
                        <List
                            component="div"
                            className="nav-transparent text-nowrap d-flex justify-content-center">
                            <Link to="/privacy">
                                <ListItem
                                    button
                                    className="text-white-50">
                                    Politicas de Seguridad
                                </ListItem>
                            </Link>
                            <Link to="/terms">
                                <ListItem
                                    button
                                    className="text-white-50">
                                    Terminos y Condiciones
                                </ListItem>
                            </Link>
                        </List>
                    </div>
                <div className="app-nav-logo d-flex w-100 pb-5 justify-content-center">
                    <Link
                        to="/"
                        title="Gungo"
                        className="d-inline-flex">
                        <div className="rounded-circle">
                            <img
                                alt="Gungo"
                                src="https://cdn.gungo.cl/gungo/web/gungo-50.png"
                            />
                        </div>
                        <div className="app-nav-logo--text">
                            <span>Bucea en Chile</span>
                            <b>Gungo</b>
                        </div>
                    </Link>
                </div>
            </Container>
        </Container>
    );
}

export default Footer;