import React, {useState} from "react";

import {
    Card,
    Grid,
    Button,
    TextField,
    InputAdornment,
    Dialog,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";
import CountUp from 'react-countup';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from "react-router-dom";


const CentersFilterDialog = (props) => {

    const [openFilter, setOpenFilter] = useState(false)
    const handleOpenFilter = () => {
        setOpenFilter(!openFilter)
    }

    return (
        <>
            <Grid container justify="center" alignItems="center" className="mb-3">
                <Grid item xs={12} md={8}>
                <Card className="mb-3 p-2 card-box card-box-border-bottom border-warning">
                    <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={3} md={2}>
                    <Button onClick={handleOpenFilter} className="btn-neutral-second btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 m-2">
                      <span className="btn-wrapper--icon">
                        <FilterListTwoToneIcon/>
                      </span>
                    </Button>
                </Grid>
                <Grid item xs={9} md={6}>
                    <TextField
                        name="q"
                        id="q"
                        variant="outlined"
                        label="Buscar..."
                        fullWidth
                        value={props.name}
                        onChange={props.onChangeName}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Link to="/newcenter" style={{float: 'right', textDecoration: 'underline'}} className="font-size-xs">Nos falta un centro? recomiendalo acá</Link>
                </Grid>
                    </Grid>
                </Card>
                </Grid>
            </Grid>
            <Dialog open={openFilter} onClose={handleOpenFilter} scroll="paper">
                <DialogTitle>
                    Aplicar Filtros a Búsqueda
                    <IconButton aria-label="close" className="btn-neutral-second" style={{position: "absolute", right: 5, top: 5, padding: 5}} onClick={handleOpenFilter}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreTwoToneIcon />}
                            aria-controls="zone-content"
                            id="zone-header"
                            className="bg-neutral-first"
                        >
                            <div className="card-header--title">
                                <small className="d-block text-uppercase mt-1">Filtrar por</small>
                                <b>Zonas</b>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} className="px-1">
                                <Grid item md={3} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="norte" checked={props.zones.norte} onChange={props.onChangeZone} />}
                                        id="zoneNorte"
                                        className="align-self-center"
                                        label="Norte"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="centro" checked={props.zones.centro} onChange={props.onChangeZone} />}
                                        id="zoneCentro"
                                        className="align-self-center"
                                        label="Centro"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="sur" checked={props.zones.sur} onChange={props.onChangeZone} />}
                                        id="zoneSur"
                                        className="align-self-center"
                                        label="Sur"
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="rapanui" checked={props.zones.rapanui} onChange={props.onChangeZone} />}
                                        id="zoneRapaNui"
                                        className="align-self-center"
                                        label="Rapa-Nui"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreTwoToneIcon />}
                            aria-controls="zone-content"
                            id="zone-header"
                            className="bg-neutral-first"
                        >
                            <div className="card-header--title">
                                <small className="d-block text-uppercase mt-1">Filtrar por</small>
                                <b>Ubicaciones</b>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} className="px-1">
                                {props.locations.map(({id, name, checked}) => (

                                    <Grid item md={6} key={`grid-${id}`}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                id={id.toString()}
                                                name={name}
                                                checked={checked}
                                                onChange={props.onChangeLocation}
                                            />}
                                            id={id.toString()}
                                            className="align-self-center"
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <div className="avatar-icon-wrapper avatar-initials avatar-icon-xs">
                        <div className="avatar-icon rounded text-white bg-dark">
                            <CountUp
                                start={0}
                                end={props.countFilteredCenters}
                                duration={3}
                            />
                        </div>
                    </div>
                        Centros encontrados
                    <Button autoFocus onClick={handleOpenFilter} variant="outlined" className="btn-neutral-second">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CentersFilterDialog