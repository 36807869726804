import React from "react";
import {Grid, LinearProgress, ListItem} from "@material-ui/core";
import CenterAvatar from "./center/CenterAvatar";
import { Link } from "react-router-dom";


const MyTopCenterRow = (props) => {

    // ToDo: calculate MIN MAX qith a function like that:
    // const valueRange = (props) => {
    //     console.log("set value ranges is runing");
    //     let lowest = Number.POSITIVE_INFINITY;
    //     let highest = Number.NEGATIVE_INFINITY;
    //     let tmp;
    //     for (let i=props.length-1; i>=0; i--) {
    //         tmp = props[i].total;
    //         if (tmp < lowest) lowest = tmp;
    //         if (tmp > highest) highest = tmp;
    //     }
    //     return {min: lowest, max: highest}
    // };
    const MIN = 0
    const MAX = props.max
    const normalise = value => (value - MIN) * 100 / (MAX - MIN);

    return (
        <ListItem className="py-3">
            <Grid container spacing={0}>
                <Grid
                    item
                    xl={6}
                    md={12}
                    className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <CenterAvatar {...props} />
                        <div>
                            <Link
                                to={"/centers/"+props.slug}
                                className="font-weight-bold text-black"
                                title={props.name}>
                                {props.name}
                            </Link>
                            <span className="text-black-50 d-block">
                                {props.region}
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid
                    item
                    xl={6}
                    md={12}
                    className="pt-3 pt-xl-0 d-flex align-items-center">
                    <div className="align-box-row flex-grow-1">
                        <div className="d-flex flex-column flex-grow-1">
                            <div className="d-flex justify-content-between text-dark">
                                <div className="ml-auto">
                                    <div className="font-weight-bold">
                                        buceos: {props.total}
                                    </div>
                                </div>
                            </div>
                            <LinearProgress
                                variant="determinate"
                                className="progress-sm progress-bar-rounded progress-bar-info"
                                value={normalise(props.total)}
                            />
                        </div>
                        {/*<Tooltip title="Haz Check In">*/}
                        {/*    <Button*/}
                        {/*        variant="contained"*/}
                        {/*        className="btn-success ml-4">*/}
                        {/*                <span className="btn-wrapper--icon">*/}
                        {/*    <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />*/}
                        {/*  </span>*/}
                        {/*    </Button>*/}
                        {/*</Tooltip>*/}
                    </div>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default MyTopCenterRow;