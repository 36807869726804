import React from "react";
import { Link } from "react-router-dom"
import {Chip, Button, Card, Grid} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';

import Rating from "@material-ui/lab/Rating";

const CenterCard = (props) => {
    const description_stripped = (props.description == null) ? "" : props.description.replace(/<[^>]+>/g, '')
    return (
        <Card className="mb-5">
            <Grid container item xs={12}>
                <Grid
                    item
                    md={4}
                    className="d-flex align-items-center justify-content-center p-2">
                    <div className="divider-v divider-v-md d-none d-lg-block" />
                    <Link
                        to={`/center/${props.slug}`}
                        className="card-img-wrapper card-box-hover rounded">
                        <img
                            alt={props.name}
                            className="hover-scale-lg"
                            src={props.image_small}
                        />
                    </Link>
                </Grid>
                <Grid
                    item
                    md={8}
                    className="d-flex pl-4 MuiGrid-direction-xs-column">
                    <div className="d-none d-lg-block py-2" />
                        <div className="font-weight-bold font-size-xxl">
                            <Link to={`/center/${props.slug}`} style={{textTransform: 'capitalize'}} >
                                {props.name}
                            </Link>
                        </div>
                        <div>
                            <LocationOnTwoToneIcon />
                            {props.region}
                        </div>
                        <div className="d-flex flex-wrap pt-2 pb-3">
                            <Rating
                                name="simple-controlled"
                                value={parseInt(props.rating)}
                                readOnly
                            />
                        </div>
                        <div className="font-size-sm text-black-50 pb-3 pr-3 text-justify">
                            {!description_stripped ? "" : description_stripped.substring(0, 300) + "..."}
                            
                                <Link to={`/center/${props.slug}`} >
                                <Button size="small" className="ml-3" variant="outlined" color="primary">
                                    Ver más
                                </Button>
                                </Link>
                            
                        </div>
                        <div className="py-2">
                        {props.locations.map((location) => (
                            <Chip
                                key={location.id}
                                variant="outlined"
                                size="small"
                                label={location.name}
                                color="primary"
                                icon={<LocationOnTwoToneIcon />}
                                className="m-1"
                            />
                        ))}
                        
                        </div>
                        {/* <div className="">
                            <Tooltip
                            classes={{ tooltip: 'text-center p-3 tooltip-info' }}
                            arrow
                            placement="top"
                            title="Ducha con Agua Caliente">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="btn-neutral-success btn-transition-none btn-pill d-30 btn-icon p-4">
                                    <BathtubTwoToneIcon />
                                </Button>
                            </Tooltip>
                            
                            <Tooltip
                            classes={{ tooltip: 'text-center p-3 tooltip-success' }}
                            arrow
                            placement="top"
                            title="Bote">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="btn-neutral-success btn-transition-none btn-pill d-30 btn-icon p-4">
                                    <DirectionsBoatTwoTone />
                                </Button>
                            </Tooltip>

                            <Tooltip
                            classes={{ tooltip: 'text-center p-3 tooltip-primary' }}
                            arrow
                            placement="top"
                            title="Hospedaje">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="btn-neutral-success btn-transition-none btn-pill d-30 btn-icon p-4">
                                    <HotelTwoToneIcon />
                                </Button>
                            </Tooltip>
                        </div> */}
                </Grid>
            </Grid>
            <div className="card-tr-actions z-over">
                
                <Button
                    className="btn-neutral-danger btn-transition-none btn-pill d-30 btn-icon p-0">
                    <FontAwesomeIcon icon={['far', 'heart']} />
                </Button>
    
            </div>
        </Card>
    );
}

export default CenterCard;