import React from "react";
import {Grid, Card} from "@material-ui/core";
import { Link } from "react-router-dom"

const Destinations = () => {
    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <h2>Destinos de Buceo</h2>
            </Grid>
            <Grid item md={6}>
                <Card className="card-transparent overflow-visible">
                    <Link
                        to="/centers?l=19"
                        className="card-img-wrapper rounded card-box-hover-rise-alt">
                        <div className="card-badges">
                            <div className="badge badge-neutral-warning h-auto px-4">Norte</div>
                        </div>
                        <img src="https://cdn.gungo.cl/gungo/images/location-chanaral-01.jpg" className="card-img-top rounded" alt="Chañaral de Aceituno" />
                        <div className="image-title-overlay--bottom px-5 py-4">
                            <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                                Avistamiento de Ballenas y Buceo en Chañaral de Aceituno
                            </h3>
                        </div>
                    </Link>
                </Card>
            </Grid>
            <Grid item md={6}>
                <Card className="card-transparent overflow-visible">
                    <Link
                        to="/centers?l=23"
                        className="card-img-wrapper rounded card-box-hover-rise-alt">
                        <div className="card-badges">
                            <div className="badge badge-neutral-warning h-auto px-4">Rapa-Nui</div>
                        </div>
                        <img src="https://cdn.gungo.cl/gungo/images/location-rapanui-01.jpg" className="card-img-top rounded" alt="Rapa Nui" />
                        <div className="image-title-overlay--bottom px-5 py-4">
                            <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                                Bucea en Rapa Nui
                            </h3>
                        </div>
                    </Link>
                </Card>
            </Grid>
            <Grid item md={4}>
                <Card className="card-transparent overflow-visible">
                    <Link
                        to="/centers?z=norte"
                        className="card-img-wrapper rounded card-box-hover-rise-alt">
                        <div className="card-badges">
                            <div className="badge badge-neutral-warning h-auto px-4">Norte</div>
                        </div>
                        <img src="https://cdn.gungo.cl/gungo/images/location-norte-01.jpg" className="card-img-top rounded" alt="Zona Norte" />
                        <div className="image-title-overlay--bottom px-5 py-4">
                            <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                                Conoce los puntos de buceo en el norte de chile
                            </h3>
                        </div>
                    </Link>
                </Card>
            </Grid>
            <Grid item md={4}>
                <Card className="card-transparent overflow-visible">
                    <Link
                        to="/centers?z=sur"
                        className="card-img-wrapper rounded card-box-hover-rise-alt">
                        <div className="card-badges">
                            <div className="badge badge-neutral-warning h-auto px-4">Sur</div>
                        </div>
                        <img src="https://cdn.gungo.cl/gungo/images/location-patagonia-01.jpg" className="card-img-top rounded" alt="Sur de Chile" />
                        <div className="image-title-overlay--bottom px-5 py-4">
                            <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                                Atrevete a bucear en el sur de Chile
                            </h3>
                        </div>
                    </Link>
                </Card>
            </Grid>
            <Grid item md={4}>
                <Card className="card-transparent overflow-visible">
                    <Link
                        to="/centers?z=centro"
                        className="card-img-wrapper rounded card-box-hover-rise-alt">
                        <div className="card-badges">
                            <div className="badge badge-neutral-warning h-auto px-4">Centro</div>
                        </div>
                        <img src="https://cdn.gungo.cl/gungo/images/location-central-01.jpg" className="card-img-top rounded" alt="..." />
                        <div className="image-title-overlay--bottom px-5 py-4">
                            <h3 className="font-size-xxl font-weight-bold m-0 text-white">
                                Descubre las inumerables opciones que ofrece el litoral central
                            </h3>
                        </div>
                    </Link>
                </Card>
            </Grid>

        </Grid>
    );
}

export default Destinations;