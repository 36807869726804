import React, {useEffect, useState} from 'react';
import {
    Grid,
    Container,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Card,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from "react-router-dom";

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import BeenhereTwoToneIcon from '@material-ui/icons/BeenhereTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';

import hero6 from '../assets/images/hero-bg/hero-1.jpg';

import { loginUser, useAuthState, useAuthDispatch } from '../Context';
import Alert from "@material-ui/lab/Alert";

function Login(props) {
    useEffect(() => {
        document.title = "Login - Gungo"
    }, [])

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            let response = await loginUser(dispatch, { user: { email: email, password: password } })
            if (!response.auth_token) return;
            props.history.push('/');
        } catch (error) {

        }
    };

    const [checkedRememberMe, setCheckedRememberMe] = useState(true);

    const handleChangeRememberMe = (event) => {
        setCheckedRememberMe(event.target.checked);
    };

    const handleKeypress = e => {
        if (e.key === "Enter") {
            handleLogin(e);
        }
    };

    return (
        <div className="app-wrapper min-vh-100 bg-white">

            <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div
                        className="bg-composed-wrapper--image opacity-6"
                        style={{ backgroundImage: 'url(' + hero6 + ')' }}
                    />
                    <div className="bg-composed-wrapper--bg bg-second opacity-7" />
                    <div className="bg-composed-wrapper--content p-3 p-md-5">
                        <Container>
                            <Card className="rounded-sm modal-content p-3 bg-white-10">
                                <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                                    <Grid container spacing={0}>
                                        <Grid
                                            item
                                            md={6}
                                            className="d-flex align-items-center justify-content-center flex-column">
                                            <div className="divider-v divider-v-lg d-none d-lg-block" />
                                            <div className="text-center mt-4">
                                                <Typography variant="h3" className="font-size-xxl mb-1 font-weight-bold">
                                                    Login
                                                </Typography>
                                                <Typography variant="subtitle1" className="mb-0 text-black-50">
                                                    Ingresa con tu usuario y contraseña
                                                </Typography>
                                                {errorMessage ? <Alert elevation={6} variant="filled" severity="warning">{errorMessage}</Alert> : null}
                                            </div>
                                            <div className="p-4 w-100">
                                                    <div className="mb-4">
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="email"
                                                            label="Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            onKeyPress={handleKeypress}
                                                            disabled={loading}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MailOutlineTwoToneIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="password"
                                                            label="Password"
                                                            type="password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onKeyPress={handleKeypress}
                                                            disabled={loading}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <LockTwoToneIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checkedRememberMe}
                                                                    onChange={handleChangeRememberMe}
                                                                    value="checked"
                                                                    color="primary"
                                                                />
                                                            }
                                                            className="font-size-md"
                                                            label="Recordarme"
                                                        />
                                                        <div>
                                                            <Link
                                                                to="/recoverpassword"
                                                                className="text-first">
                                                                Recuperar Contraseña
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="text-center py-4">
                                                        <Button onClick={handleLogin} disabled={loading} className="btn-second font-weight-bold w-50 my-2 py-3">
                                                            Ingresar
                                                        </Button>
                                                    </div>
                                                    <div className="text-center text-black-50 mt-3">
                                                        No tienes una cuenta?{' '}
                                                        <Link
                                                            to="/register"
                                                            className="text-first">
                                                            Registrate
                                                        </Link>
                                                    </div>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            className="d-flex align-items-center justify-content-center flex-column bg-secondary">
                                            <div className="p-3">
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <FavoriteTwoToneIcon style={{color: "red"}} />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Favoritos
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Guarda tus centros de buceo favoritos.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <BeenhereTwoToneIcon style={{color: "green"}} />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Check in
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Registra tus visitas en los centros de buceo y lleva un registro de cuantas veces has buceado a lo largo de Chile, mientras desbloquea desafios. En cuantas regionse has buceado? cuantos centros distintos conoces?
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-4">
                                                    <div className="d-block d-xl-flex">
                                                        <div className="mt-0 mt-xl-1 mb-md-2 mb-lg-0">
                                                            <StarTwoToneIcon style={{color: "#ffb400"}}  />
                                                        </div>
                                                        <div className="pl-0 pl-xl-3">
                                                            <div className="text-black font-weight-bold font-size-lg mb-1">
                                                                Reviews
                                                            </div>
                                                            <p className="mb-0 text-black-50">
                                                                Califica los centros de buceo que has visitado y ve lo que otros buceadores opinan.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Card>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;