import React, { useEffect, useState } from "react";

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchAPI } from "../api";


const AutocompleteField = (props) => {

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetchAPI('GET', '/locations', '', false)
        .then(data => {
            setLocations(data)
        })
        .catch(error =>
            console.log("error al conectar con API")
        )
    }, []);

    return (
        <Autocomplete
            multiple
            id="fixed-tags-demo"
            size="small"
            defaultValue={[]}
            options={locations}
            getOptionLabel={(option) => option.name}
            onChange={props.onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={props.onChange}
                    label="Ubicación"
                    variant="outlined"
                    placeholder="Todas las Ubicaciones"
                    fullWidth
                />
            )}
        />
    );
};

export default AutocompleteField;