import React from "react";
import { format } from "date-fns"
import { es } from 'date-fns/locale'

import BubbleChartTwoToneIcon from "@material-ui/icons/BubbleChartTwoTone";
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone';

const TimelineEvent = (props) => {

    const renderIcon = (name) => {
        let iconComponent = '';
        switch (name) {
            case 'dive':
                iconComponent = <div className="timeline-item--icon-wrapper bg-info text-white"><BubbleChartTwoToneIcon /></div>;
                break;
            default:
                iconComponent = <div className="timeline-item--icon-wrapper bg-first text-white"><EventAvailableTwoToneIcon /></div>;
        }
        return iconComponent;
    };

    return (
        <div className="timeline-item timeline-item-icon">
            <div className="timeline-item--content">
                {renderIcon(props.event_name)}
                <h4 className="timeline-item--label mb-2 font-weight-bold">
                    {format(new Date(props.event_date+"T12:00"), "dd MMM yyyy", {locale: es})}
                </h4>
                <p>{props.event_desc}</p>
            </div>
        </div>
    );
};

export default TimelineEvent;