import React from "react";
import DoneTwoToneIcon from "@material-ui/icons/DoneTwoTone";
import DirectionsBoatTwoToneIcon from "@material-ui/icons/DirectionsBoatTwoTone";
import BubbleChartTwoToneIcon from '@material-ui/icons/BubbleChartTwoTone';
import FeedbackTwoToneIcon from '@material-ui/icons/FeedbackTwoTone';
import ExploreTwoToneIcon from '@material-ui/icons/ExploreTwoTone';
import {Grid} from "@material-ui/core";

const Badge = (props) => {
    const {
        active,
        points,
        icon,
        unit
    } = props;

    const renderIcon = (icon) => {
        let iconComponent = '';
        switch (icon) {
            case "BubbleChartTwoToneIcon":
                iconComponent = <BubbleChartTwoToneIcon />;
                break;
            case "FeedbackTwoToneIcon":
                iconComponent = <FeedbackTwoToneIcon />;
                break;
            case "ExploreTwoToneIcon":
                iconComponent = <ExploreTwoToneIcon />;
                break;
            default:
                iconComponent = <DirectionsBoatTwoToneIcon />;
        }
        return iconComponent;
    };

    return (
        <Grid item xs={6} md={4} lg={2}>
            <a href="#/" onClick={e => e.preventDefault()} className={`${active ? 'active' : ''} m-3 btn-input-select`}>
                <div className="selected-icon">
                    <DoneTwoToneIcon />
                </div>
                <div className={`${active ? 'bg-warning' : 'bg-dark'} d-30 text-white d-flex align-items-center justify-content-center rounded-pill`}>
                    {renderIcon(icon)}
                </div>
                <div className="font-weight-bold mt-2">
                    {points} {unit}
                </div>
            </a>
        </Grid>
    );
};

export default Badge;