import React, {useEffect, useState} from 'react';
import {Grid, Typography, Container, TextField, InputAdornment, Button} from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import {useHistory} from "react-router-dom";

function NotFound(props) {

    useEffect(() => {
        document.title = "Página no encontrada - Gungo"
    }, [])

    const [searchStatus, setSearchStatus] = useState(false)
    const [search, setSearch] = useState()
    const toggleSearch = () => setSearchStatus(!searchStatus)
    const handleChangeSearch = (e) => {
        setSearch(e.target.value)
    }
    const handleKeypress = e => {
        if (e.key === "Enter") {
            handleClick();
        }
    }
    let history = useHistory();
    const handleClick = () => {
        let locationParams = "";
        locationParams = (search == null) ?  "?q=" : `?q=${search}`
        history.push(`/centers${locationParams}`);
    }

    return (
        <Container fixed maxWidth="md" className="py-4">
            <Grid container spacing={2} direction="row" alignItems="center" justify="center" justifyContente="center">
                <Grid item xs={5}>
                    <img alt="error, pagina no encontrada" src="https://cdn.gungo.cl/gungo/web/gungo-e404-300.png"/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h4" align="center">La página que buscas no existe</Typography>
                    <Typography variant="h5" align="center">Recuerda: Busca por un minuto, luego vuelve a la superficie</Typography>
                    <Typography variant="subtitle1" align="center">Quizá movimos el contenido que buscabas, busca tu destino de buceo acá</Typography>
                </Grid>
                <Grid item md={5} >
                    <TextField
                        variant="outlined"
                        size="small"
                        id="input-with-icon-textfield22-1"
                        className="w-100"
                        name="q"
                        onFocus={toggleSearch}
                        onBlur={toggleSearch}
                        onChange={handleChangeSearch}
                        onKeyPress={handleKeypress}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item md={2} >
                    <Button type="submit" size="large" variant="contained" className="btn-primary" onClick={handleClick} >
                        <span className="btn-wrapper--label">Buscar</span>
                        <span className="btn-wrapper--icon">
                                    <SearchTwoToneIcon />
                                </span>
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default NotFound;