import React from 'react';

import clsx from 'clsx';

import CheckIn from "../components/CheckIn";

const PageTitle = (props) => {
    const {
        pageTitleStyle = '',
        pageTitleBackground = '',
        pageTitleShadow = true,
        pageTitleIconBox = true,
        pageTitleDescription = true ,
        titleHeading = true,
        titleDescription = true,
        iconHeader = '',
        center = {},
        children
    } = props;

    return (
        <>
            <div
                className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
                    'app-page-title--shadow': pageTitleShadow
                })}>
                <div className="app-page-title--first mt-2">
                    {pageTitleIconBox && (
                        <div className="app-page-title--iconbox d-50">
                            <div className="d-50 d-flex align-items-center justify-content-center display-1">
                                <div className="avatar-icon shadow-none d-50 rounded">
                                <img src={iconHeader} alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="app-page-title--heading">
                        <h1>{titleHeading}</h1>
                        {pageTitleDescription && (
                            <div className="app-page-title--description">
                                {titleDescription}
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {children}
                    <CheckIn center={center} />
                </div>
            </div>
        </>
    );
};

export default PageTitle;
