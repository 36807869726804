import React, {useEffect, useState} from "react";

import {Grid, Card, TextField, Button, Container, Snackbar} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup"
import {fetchAPI} from "../api";
import MuiAlert from "@material-ui/lab/Alert";

const validationSchema = yup.object({
    name: yup
        .string('Ingresa tu nombre')
        .required('Nombre es obligatorio'),
    email: yup
        .string('Ingresa tu email')
        .email('Ingresa un email valido')
        .required('Email es obligatorio'),
    message: yup
        .string('Dejanos un mensaje')
        .required('Mensaje es obligatorio')
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Contact = () => {
    useEffect(() => {
        document.title = "Contáctanos - Gungo"
    }, [])

    const [confirmation, setConfirmation] = useState({
        open: false,
        message: '',
        type: ''
    })
    const handleCloseConfirmation = () => {
        setConfirmation((s) => ({ ...s, open: false }))
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            fetchAPI('POST', '/forms/contact', values, false)
                .then(data => {
                    if (data.sent === "ok") {
                        setConfirmation({
                            open: true,
                            type: 'success',
                            message: `Recibimos tu Mensaje. Gracias por contactarnos`
                        })
                        resetForm()
                    }
                })
        },
    })

    return (
        <Container fixed maxWidth="lg">

        <Card className="p-4 mt-4">
            <h2 className="mb-3">Ponte en contacto con Gungo</h2>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name"
                            name="name"
                            label="Nombre"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="message"
                            name="message"
                            label="Mensaje"
                            multiline
                            rows={4}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                            className="btn-primary py-3">
                            Enviar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
            <Snackbar open={confirmation.open} autoHideDuration={6000} onClose={handleCloseConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseConfirmation} severity={confirmation.type}>
                    {confirmation.message}
                </Alert>
            </Snackbar>
        </Container>
    )

}

export default Contact