const GUNGO_API = process.env.REACT_APP_API

export function fetchAPI(method, path, payload= '', auth_required = false) {
    const url = GUNGO_API + path

    let headers = {
        'Content-Type': 'application/json'
    }
    let token = '';
    if (auth_required) {
        token = JSON.parse(localStorage.getItem('currentUser')).auth_token;

        headers['Authorization'] = 'Bearer ' + token;
    }

    const requestOptions = {
        method: method,
        headers: headers
    };

    if (payload) {
        requestOptions['body'] = JSON.stringify(payload)
    }

    return fetch(url, requestOptions)
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("error en el servidor")
            }

        })
}