import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import { Container, Grid } from '@material-ui/core';
import { useAuthState } from "../../Context";

import HeaderUserbox from "./HeaderUserbox";
import HeaderLogo from "./HeaderLogo";

import LoginLinks from "./LoginLinks";

const useStyles = makeStyles((theme) => ({
    root: {
        top: 0,
        backgroundColor: 'rgba(255,255,255,.95)',
        position: 'sticky',
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        background: 'transparent',
        marginBottom: 0,
        transition: 'all .3s'

    }
}));

const Header = (props) => {

    const classes = useStyles()

    const userDetails = useAuthState()

    return (
        <div
            className={clsx(
                'app-header',
                classes.root,
                'app-header--opacity-bg',
                'app-header--shadow'
            )}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <HeaderLogo />
                    </Grid>
                    <Grid item xs={3} lg={5}> </Grid>
                    <Grid item xs={5} lg={3}>
                        { userDetails.token ? (<HeaderUserbox />) : (<LoginLinks />) }
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default Header;