import React, {useEffect} from "react"
import {Container, Grid, Typography} from "@material-ui/core";

import hero from  '../assets/images/hero-bg/hero-2.jpg'

const Terms = () => {

    useEffect(() => {
        document.title = "Términos y Condiciones - Gungo"
    }, [])

    return (
        <div
            className="hero-wrapper bg-composed-wrapper bg-second z-below">
            <div className="hero-wrapper--content flex-column">
                <div className="shape-container-bottom-2 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,288L34.3,250.7C68.6,213,137,139,206,144C274.3,149,343,235,411,229.3C480,224,549,128,617,101.3C685.7,75,754,117,823,122.7C891.4,128,960,96,1029,112C1097.1,128,1166,192,1234,186.7C1302.9,181,1371,107,1406,69.3L1440,32L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
                    </svg>
                </div>
                <div
                    className="bg-composed-wrapper--image opacity-3"
                    style={{ backgroundImage: 'url(' + hero + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-night-sky opacity-4" />
                <div className="bg-composed-wrapper--content pb-5">
                    <Container fixed maxWidth="md" className="py-4">
                        <div className="py-5 py-sm-0 text-white opacity-8 font-size-lg text-justify">
                            <Typography variant="h4" className="font-weight-bold mb-3">TÉRMINOS LEGALES Y CONDICIONES GENERALES DE USO DEL SITIO WEB GUNGO.CL</Typography>
                            <Grid item xs={10}>
                                <Typography variant="h5" className="font-weight-bold mb-3">PRIMERO: GENERALIDADES.</Typography>
                                <p>El presente documento, elaborado con fecha <strong>18 de junio de 2021</strong>, regula los términos y condiciones generales aplicables al acceso y uso que el Usuario realizará del Sitio Web: <strong>GUNGO.CL</strong> (en adelante "<strong>GUNGO</strong>", "<strong>GUNGO.CL</strong>" o "el Sitio Web" indistintamente), así como a cualquier tipo de información, contenido, imagen, video, audio u otro material comunicado o presente en el Sitio Web.</p>
                                <p>Para efectos del presente documento se entenderá como "Sitio Web": la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de la navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, "Contenidos") y todos aquellos servicios o recuerdos en línea que en su caso ofrezca a los usuarios (en adelante, "Servicios").</p>
                                <p><strong>GUNGO</strong> se reserva la facultad de modificar, en cualquier momento, y sin previo aviso, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento <strong>GUNGO</strong> pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.</p>
                                <p>El acceso y uso del sitio web y del contenido relacionado o anexo, descrito en el presente documento, se regirá íntegramente por las leyes de la República de Chile. Por consiguiente, las visitas que el usuario realice en la plataforma que representa el sitio web, así como los efectos jurídicos que éstas pudieran tener, quedan sometidos a las leyes y a la jurisdicción de los tribunales de justicia de la República de Chile. De particular importancia resultan la aplicación de la Ley N° 19.628 de Protección de Datos Personales y la Ley N° 19.496 sobre Derechos del Consumidor.</p>

                                <p>Adicionalmente, este sitio web y sus contenidos,  están destinados a todo tipo de usuarios, sin importar su nacionalidad y lugar de residencia, no bien existe primacía respecto a la aplicación de la legislación chilena para los casos puntuales.</p>
                                <p>Además de los presentes Terminos y Condiciones Generales, el Sitio Web dispone de una Política de Privacidad que describe la forma en que se procesa la información y datos personales del Usuario cuando éste visita el Sitio Web, así como la forma en que se utilizan y almacenan las cookies.</p>
                                <p>Para mayor información, visite la Política de Privacidad contenida en el Sitio Web.</p>
                                <p>Al acceder al Sitio Web, el Usuario declara estar en conocimiento de la Política de Privacidad.</p>


                                <p>Se recomienda al usuario leer detenidamente el contenido de estos Términos y Condiciones Generales de Uso, así como imprimir y conservar una copia de éstas en una unidad de disco local de almacenamiento, o de almacenamiento portátil, para su conveniencia y seguridad.</p>

                                <Typography variant="h5" className="font-weight-bold mb-3">SEGUNDO.- INFORMACIÓN DEL SITIO WEB GUNGO.CL.</Typography>
                                <p>La titularidad de <strong>GUNGO.CL</strong> corresponde a <strong>CRISTIAN BARAHONA GARCÍA</strong>.</p>
                                <p>Adicionalmente, para efectos de cualquier tipo de comunicación, presentación, consulta o reclamo relacionados con el uso o funcionamiento del Sitio Web, <strong>GUNGO</strong> recibirá todo tipo de comunicaciones a través del formulario de contactos disponible en <a href="/contact" target="_blank">http://gungo.cl/contact</a>.</p>

                                <Typography variant="h5" className="font-weight-bold mb-3">TERCERO.- USUARIO.</Typography>
                                <p>El acceso a este Sitio Web, la navegación y uso del mismo, así como cualquier espacio habilitado para interacciones entre Usuarios, o entre éste y el Sitio Web (secciones de comentarios, espacios de blogging y micro blogging, y extensiones destinadas a esta función), conferirán a las personas la condición de "Usuario". Por lo tanto, se entienden aceptar los Términos y Condiciones Presentes en este documento desde el momento de acceder al Sitio Web o a sus contenidos, así como también todo tipo de futuras modificaciones que se hicieran a los Términos y Condiciones, sin perjuicio de lo que pudiera establecer futura legislación dictada en la República de Chile destinada a regular esta clase de documentos, o que fuera aplicable al uso de Sitios Web.</p>
                                <p>Adicionalmente, el acceso al Sitio Web por parte del Usuario tendrá un carácter libre y gratuito, sin que éste tenga que pagar por acceder al sitio o sus contenidos, más allá del costo de la conexión a internet, en los casos que correspondiera.</p>

                                <Typography variant="h6" className="font-weight-bold mb-3">Registro del Usuario.</Typography>
                                <p>Será requisito necesario para el uso del Sitio Web la aceptación de los presentes Términos y Condiciones al momento del registro por parte del Usuario. Adicionalmente, el Usuario deberá designar una clave o contraseña de acceso.</p>
                                <p>Se entenderán conocidos y aceptados estos Términos y Condiciones por el sólo hecho de registrarse el Usuario, acto en el cual se incluirá una manifestación expresa del Usuario sobre el conocimiento de las presentes condiciones de uso.</p>
                                <p>El registro de cada Usuario se verificará completando y suscribiendo el formulario que para estos efectos se proporcionará por parte del Sitio Web, así como su posterior envío, el cual se concretará por medio de hacer "click" el Usuario en el elemento respectivo dentro del Sitio Web.</p>

                                <Typography variant="h6" className="font-weight-bold mb-3">Clave Secreta.</Typography>
                                <p>Una vez registrado, el Usuario tendrá a su disposición un nombre de usuario y una contraseña o clave secreta, por medio de los cuales no sólo podrá ingresar al Sitio Web y tener acceso a sus contenidos, sino también un acceso personalizado, confidencial y seguro. El Usuario tendrá la posibilidad de cambiar su contraseña o clave secreta, para lo cual existirá un procedimiento regulado dentro del Sitio Web y su estructura.</p>
                                <p>El Usuario asume totalmente su responsabilidad por el mantenimiento de la confidencialidad de su contraseña o clave secreta registrada en el Sitio Web. Dicha contraseña o clave es de uso personal, por lo que su entrega voluntaria a terceros por parte del Usuario, no acarrea ningún tipo de responsabilidad por parte de <strong>GUNGO</strong>, ni de sus afiliados y representantes, en caso de uso malicioso.</p>

                                <Typography variant="h6" className="font-weight-bold mb-3">Responsabilidad de los Usuarios.</Typography>
                                <p><strong>GUNGO</strong> entrega al Usuario un servicio caracterizado por la diversidad del contenido proporcionado. El Usuario asume su responsabilidad al ingresar al Sitio Web, para realizar un correcto uso del mismo y sus contenidos. Así, esta responsabilidad se extenderá, de forma no taxativa, a:</p>
                                <p style={{paddingLeft: '30px'}}><strong> a.-</strong> Usar la información, Contenidos y/o Servicios y datos ofrecidos por <strong>GUNGO</strong>, sin que sea contrario a estos Términos y Condiciones, así como al Ordenamiento Jurídico Chileno y a la Moral y el Orden Público, o que de cualquier otra forma pudieran acarrear la vulneración de derechos de terceros, o el funcionamiento y operaciones normales del Sitio Web.</p>
                                <p style={{paddingLeft: '30px'}}><strong>b.-</strong> La veracidad y licitud de los datos e información aportados por el Usuario en los formularios de registro presentes en el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a <strong>GUNGO</strong> acerca de cualquier hecho relacionado con el uso indebido de información registrada en dichos formularios, tales como, pero no sólo, robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.</p>
                                <p><strong>GUNGO</strong> se reserva el derecho de retirar todos aquellos comentarios e interacciones emanados de Usuarios que vulneren la ley, el respeto a la dignidad a la persona, que sean discriminatorios en base a raza, nacionalidad, sexo, orientación sexual, estirpe o condición, así como también aquellos que contengan contenido pornográfico o que constituyan mensajes conocidos como "spam", o que atenten contra la juventud o infancia, el orden, o la seguridad pública o que, a juicio de <strong>GUNGO</strong>, no resulten apropiados para el Sitio Web.</p>
                                <p>De todas maneras, <strong>GUNGO</strong> no se hace responsable de las opiniones, de cualquier tipo, emitidas por los Usuarios mediante comentarios u herramientas o extensiones de blogging o microblogging.</p>
                                <p>El simple acceso a <strong>GUNGO.CL</strong> no supone una relación comercial entre <strong>GUNGO</strong> y el Usuario.</p>

                                <Typography variant="h6" className="font-weight-bold mb-3">Capacidad Legal.</Typography>
                                <p>El Sitio Web no requiere una edad mínima para poder acceder a su Contenido y Servicios; por lo tanto, estos Términos y Condiciones se aplicarán indistintamente a todos los Usuarios del Sitio Web que cumplan con lo estipulado en este documento.</p>
                                <p>Aún así, las reglas legales de capacidad establecidas en el ordenamiento jurídico regirán en todos aquellos casos donde su aplicación sea pertinente. Por tanto, si una persona no tiene capacidad legal para contratar, debe abstenerse de utilizar los Contenidos y Servicios que requieran de esta capacidad. <strong>GUNGO</strong> podrá, en cualquier momento, en forma temporal o definitiva, suspender la participación de usuarios sobre los cuales se compruebe que carecen de capacidad legal para usar de los Servicios y Contenido que la requieran, o que proporcionen información falsa, inexacta o fraudulenta al Sitio Web.</p>
                                <p>El Sitio Web <strong>GUNGO.CL</strong> está dirigido principalmente a Usuarios residentes en la República de Chile. <strong>GUNGO</strong> no asegura que el Sitio Web cumpla, parcial o totalmente, con legislaciones de otros países. Si el Usuario reside o está domiciliado en un país extranjero, y decide acceder y/o navegar en este Sitio Web, lo hará bajo su propia responsabilidad, asegurándose de que tal acceso y navegación cumpla con lo dispuesto en la legislación local que le sea aplicable, no asumiendo <strong>GUNGO</strong> responsabilidad alguna que pueda derivar de dicho acceso.</p>

                                <Typography variant="h5" className="font-weight-bold mb-3">CUARTO.- DERECHOS Y GARANTÍAS DEL USUARIO DEL SITIO.</Typography>
                                <p>El Usuario gozará de todos los derechos que le reconoce la legislación sobre protección al consumidor vigente en Chile, adicionalmente a los derechos que le otorgan estos Términos y Condiciones. Además, el Usuario dispondrá en todo momento de los derechos de información, rectificación y cancelación de los datos personales conforme a la Ley N° 19.628 sobre protección de datos personales.</p>


                                <Typography variant="h5" className="font-weight-bold mb-3">QUINTO.- RESPONSABILIDAD.</Typography>
                                <p><strong>GUNGO</strong> no responderá, en ningún caso, por los siguientes hechos:</p>
                                <p style={{paddingLeft: '30px'}}><strong>a.-</strong> Uso indebido que Usuarios o visitantes al Sitio Web realicen del contenido almacenado, así como de los derechos de propiedad industrial o intelectual contenidos en el Sitio Web.</p>
                                <p style={{paddingLeft: '30px'}}><strong>b.-</strong> Daños y perjuicios, concretos o eventuales, causados a Usuarios derivados del funcionamiento de las herramientas de búsqueda del Sitio Web, así como de errores generados por elementos técnicos del Sitio o del motor de búsqueda.</p>
                                <p style={{paddingLeft: '30px'}}><strong>c.-</strong> Contenido de Sitios Web a las que los Usuarios puedan acceder con o sin autorización de <strong>GUNGO</strong>.</p>
                                <p style={{paddingLeft: '30px'}}><strong>d.-</strong> Pérdida, mal uso o uso no autorizado de contraseña o códigos de validación, sea por parte del Usuario o de un tercero, de la forma expresada en estos Términos y Condiciones. De la misma forma, las partes reconocen y dejan constancia que el soporte computacional entregado por <strong>GUNGO.CL</strong> no es infalible, por lo tanto, pueden verificarse circunstancias ajenas a la voluntad de <strong>GUNGO</strong> que puedan causar que el Sitio Web o su plataforma no se encuentren operativos durante un cierto período de tiempo.</p>
                                <p style={{paddingLeft: '30px'}}><strong>e.-</strong> Información referida a <strong>GUNGO</strong> que se encuentre en Sitios Web distintos o ajenos a <strong>GUNGO.CL</strong>.</p>
                                <p>En caso de estar ante alguno de estos supuestos, <strong>GUNGO</strong> tomará todas las medidas para reestablecer el correcto funcionamiento del Sitio Web y de su sistema comunicacional lo más pronto posible, sin que pudiera imputársele algún tipo de responsabilidad por aquello.</p>
                                <p><strong>GUNGO</strong> no asegura disponibilidad ni continuidad de funcionamiento del Sitio Web, y tampoco que en cualquier momento, los Usuarios puedan acceder a él.</p>
                                <p><strong>GUNGO</strong> tampoco es responsable por la existencia de virus u otros elementos perjudiciales en los documentos o archivos almacenados en los sistemas informáticos de todo tipo de propiedad de los Usuarios. <strong>GUNGO</strong> no responderá de perjuicios causados al Usuario, derivados del uso indebido de las tecnologías y plataformas puestas a su disposición, cualquiera sea la forma en que se utilicen inadecuadamente estos elementos tecnológicos. Asimismo, <strong>GUNGO</strong> no responderá por daños producidos por el uso indebido o mala fe de los Usuarios al utilizar <strong>GUNGO.CL</strong>.</p>

                                <p>Al momento de registrarse, hacer login y para comunicarse, <strong>GUNGO.CL</strong> utiliza certificados digitales de seguridad (SSL), con el fin de encriptar la información. <strong>GUNGO</strong> no almacena ni conserva datos financieros de sus Usuarios.</p>
                                <p><strong>SEXTO.- POLÍTICA DE SEGURIDAD DE DATOS.</strong></p>
                                <p><strong>GUNGO</strong> adoptará todas las medidas necesarias para resguardar los datos de sus Usuarios y clave secreta, como sistemas de encriptado de información, certificados de seguridad y otros semejantes o equivalentes que <strong>GUNGO</strong> considere prudentes para estos efectos. En caso de realizarse cambios hechos por el Usuario en la información registrada o ingresada en el Sitio Web, o bien, si se produce la detección de cualquier tipo de irregularidad atribuible al Usuario relacionada con la información entregada  o simplemente como medida de protección de identidad, el personal del Sitio Web se pondrá en contacto con el Usuario, sea por vía telefónica o por correo electrónico, para corroborar los datos y la información, así como para evitar la existencia de posibles fraudes.</p>


                                <Typography variant="h6" className="font-weight-bold mb-3">Datos Personales.</Typography>
                                <p>Al acceder al Sitio Web <strong>GUNGO.CL</strong>, los Usuarios o visitantes garantizan que la información que proporcionan para ingresar  es veraz, completa, exacta y actualizada. </p>
                                <p>De acuerdo a lo establecido en la Ley N° 19.628 de Protección de Datos Personales, los datos de este tipo que se suministren al Sitio Web, pasan a formar parte de una base de datos perteneciente a <strong>GUNGO</strong> y serán destinados única y exclusivamente para los fines que motivaron su entrega, especialmente para la comunicación entre <strong>GUNGO</strong> y sus Usuarios, y responder consultas. Estos datos nunca serán comunicados o compartidos con otras empresas sin expresa autorización de su titular (el Usuario) ni serán transferidos internacionalmente.</p>
                                <p><strong>GUNGO</strong> jamás solicitará a sus Usuarios la entrega de datos personales o financieros a través de correos electrónicos.</p>
                                <p><strong>GUNGO</strong> presume que los datos incorporados por los Usuarios o por personas autorizadas por éstos son correctos y exactos. </p>
                                <p>Sin perjuicio de lo anterior, <strong>GUNGO</strong> garantiza a todos sus Usuarios el libre ejercicio de los derechos contemplados en la Ley N° 19.628 de Protección de Datos Personales en lo relativo a la información, modificación, cancelación y bloqueo de sus datos personales. En consecuencia, los Usuarios podrán realizar requerimientos relacionados con los ya mencionados derechos, los que serán respondidos por <strong>GUNGO</strong> en un plazo no superior a dos días corridos.</p>

                                <Typography variant="h5" className="font-weight-bold mb-3">SÉPTIMO.- PROPIEDAD INTELECTUAL Y DERECHOS RESERVADOS.</Typography>
                                <p>Todos los derechos presentes en el Sitio Web, incluyendo la propiedad intelectual, respecto al mismo Sitio Web, páginas y otro tipo de contenido, como podría ser, de forma no taxativa, textos de todo tipo, material gráfico o audiovisual (incluyendo, pero no limitado a imágenes, clips de audio y videos), logos y logotipos, íconos de todo tipo, material y contenido descargable, recopilaciones de datos e información y códigos fuente de la página, son de propiedad de <strong>GUNGO</strong>.</p>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <div className="shape-container-top-1 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Terms