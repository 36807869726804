import React from "react";
import { Link } from "react-router-dom";

const HeaderLogo = () => {
  return (
      <div className="app-nav-logo">
          <Link
              to="/"
              aria-current="page"
              title="Gungo"
              className="app-nav-logo app-nav-logo--dark">
              <div className="d-40 mr-2 rounded-sm bg-white border-0">
                  <img alt="Gungo" src="https://cdn.gungo.cl/gungo/web/gungo-50.png" />
              </div>
              <div className="app-nav-logo--text">
                  <span>bucea en chile</span>
                  <b>Gungo</b>
                  <span style={{color: '#f4772e'}}>BETA</span>
              </div>
          </Link>
      </div>
  );
};

export default HeaderLogo;