import React, {useEffect, useState} from "react";
import { Card } from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

import TimelineEvent from "./TimelineEvent";
import {fetchAPI} from "../api";
import {useAuthState} from "../Context";

const MyTimeline = () => {
    const userDetails = useAuthState()
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchAPI('GET', '/users/me/timeline', '', true)
            .then(data => {
                setEvents(data);
            })
    }, [userDetails]);
    return (
        <Card className="card-box p-0">
            <div className="card-header">
                <div className="card-header--title">
                    <small>Actividades</small>
                    <b>Mis últimas actividades en Gungo</b>
                </div>
            </div>
            <div className="card-body">
            <div className="shadow-overflow">
                <PerfectScrollbar
                    className="scroll-area-sm"
                    options={{ wheelPropagation: false }}>
                    <div className="p-3">
                        <div className="timeline-list">
                            {events.map((event) => (
                                <TimelineEvent key={event.id} {...event} />
                            ))}

                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
            </div>
        </Card>
    );
}

export default MyTimeline;
