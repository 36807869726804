import React, {useEffect} from 'react';
import SearchBox from "../components/SearchBox";
import {Container, Grid} from "@material-ui/core";
import Destinations from "../components/Destinations";
import FeaturedCenters from "../components/FeaturedCenters";
import CarouselExperiences from "../components/CarouselExperiences";
import {Link} from "react-router-dom";
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';
import ArrowForwardIosTwoToneIcon from '@material-ui/icons/ArrowForwardIosTwoTone';

const Home = () => {
    useEffect(() => {
        document.title = "Gungo - Buceo en Chile"
    }, [])

    return (
        <>
            <SearchBox />
            <div className="bg-heavy-rain p-2 rounded m-2">
            <Container fixed maxWidth="lg">
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} md={6}>
                        <Link
                            to="/howto"
                            className="card bg-white btn rounded text-left bg-white p-2 m2-4"
                        >
                            <div className="d-flex align-items-center">
                                <div className="d-40 rounded-circle bg-second text-white mr-3 text-center">
                                    <SchoolTwoToneIcon />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="text-black-50 pb-1">Aprende lo que puedes hacer con Gungo</div>
                                    <div className="text-success"> en menos de 2 min.</div>
                                </div>
                                <div className="font-size-sm opacity-5">
                                    <ArrowForwardIosTwoToneIcon />
                                </div>
                            </div>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
            </div>
            <Container fixed maxWidth="lg">
                <FeaturedCenters />
            </Container>
            <Container fixed maxWidth="lg" className="mt-4">
                <Destinations />
            </Container>
            <CarouselExperiences />
        </>
    );

};

export default Home;