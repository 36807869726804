import React, {useEffect, useState} from "react";
import { Container, Grid, GridList, GridListTile, GridListTileBar, Hidden, IconButton } from "@material-ui/core";

import {useParams, useHistory} from "react-router-dom";

import PageTitle from "../layouts/PageTitle";
import {fetchAPI} from "../api";

import ReactBnbGallery from 'react-bnb-gallery'
import 'react-bnb-gallery/dist/style.css';

import {ReviewsContext} from "../Context";
import CenterDescription from "../components/center/CenterDescription";
import CenterFacilities from "../components/center/CenterFacilities";
import CenterStatistics from "../components/center/CenterStatistics";
import CenterReviews from "../components/center/CenterReviews";
import CenterMap from "../components/center/CenterMap";
import CenterTopDiverList from "../components/center/CenterTopDiverList";
import PhotoLibraryTwoToneIcon from '@material-ui/icons/PhotoLibraryTwoTone';

const PHOTOS = [
    {
    photo: "https://cdn.gungo.cl/gungo/default/center-image.jpg",
    caption: "Centro sin fotos",
    subcaption: "Subida por: Gungo",
    thumbnail: "https://cdn.gungo.cl/gungo/default/small_center-image.jpg",
    number: 1
    },
    {
    photo: "https://cdn.gungo.cl/gungo/default/center-image.jpg",
    caption: "Centro sin fotos",
    subcaption: "Subida por: Gungo",
    thumbnail: "https://cdn.gungo.cl/gungo/default/small_center-image.jpg",
    number: 2
    },
    {
    photo: "https://cdn.gungo.cl/gungo/default/center-image.jpg",
    caption: "Centro sin fotos",
    subcaption: "Subida por: Gungo",
    thumbnail: "https://cdn.gungo.cl/gungo/default/small_center-image.jpg",
    number: 3
    }
]

const Center = () => {

    const {slug} = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const [center, setCenter] = useState({})
    const [gallery, setGallery] = useState(PHOTOS)
    const [headerPhotos, setHeaderPhotos] = useState(PHOTOS)
    let history = useHistory()
    const [reviewsContext, setReviewsContext] = useState([])

    useEffect(() => {
        fetchAPI('GET', `/centers/${slug}`, '', false)
        .then(data => {
            setCenter(data)
        }).catch(error => {
            history.push("/404")

        })

        fetchAPI('GET', `/centers/${slug}/photos`, '', false)
        .then(photoData => {
            setGallery(photoData)
        }).catch(error => console.log("error api"))
    }, [slug])

    useEffect(() => {
        const mainPhotos = []
        for (let i = 0; i < 3; i++) {
            if (gallery[i]) {
                mainPhotos[i] = gallery[i]
            } else {
                mainPhotos[i] = PHOTOS[i]
            }
        }
        setHeaderPhotos(mainPhotos)
    }, [gallery])

    useEffect(() => {
        const title = center.name ? `${center.name} en Gungo` : 'Gungo'
        document.title = title
    }, [center])

    return (
        <Container fixed maxWidth="lg">
            <PageTitle
                titleHeading={center.name}
                titleDescription={center.region?.name}
                iconHeader={center.logo_thumb}
                center={{name: center.name, slug: center.slug}}>
            </PageTitle>

            <Hidden mdUp>
                <Grid container>
                    <Grid item >
                        <a href="#/" onClick={() => setIsOpen(true)} className="card-img-wrapper rounded">
                            <GridList cellHeight={200} cols={1} >
                                <GridListTile key={headerPhotos[0].photo} >
                                    <img src={headerPhotos[0].photo} alt="Fotos del Centro" />
                                    <GridListTileBar
                                        title="Ver Galería de Fotos"
                                        actionPosition="right"
                                        actionIcon={
                                            <IconButton aria-label="Ver Galería de Fotos" style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                                <PhotoLibraryTwoToneIcon />
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            </GridList>
                        </a>
                    </Grid>
                </Grid>
            </Hidden>

            <Hidden smDown>
                <Grid container spacing={2}>
                    <Grid item md={6} >
                        <a href="#/" onClick={() => setIsOpen(true)} className="card-img-wrapper rounded-left">
                        <GridList cellHeight={328} cols={1} >
                            <GridListTile key={headerPhotos[0].photo} >
                                <img src={headerPhotos[0].photo} alt="Fotos del Centro" />
                                <GridListTileBar
                                    title="Ver Galería de Fotos"
                                    actionPosition="right"
                                    actionIcon={
                                        <IconButton aria-label="Ver Galería de Fotos" style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                            <PhotoLibraryTwoToneIcon />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        </GridList>
                        </a>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item className="mb-2">
                            <a href="#/" onClick={() => setIsOpen(true)} className="card-img-wrapper rounded-right">
                            <GridList cellHeight={160}  cols={1}>
                            <GridListTile key={headerPhotos[1].photo}>
                                <img src={headerPhotos[1].photo} alt="Fotos del Centro" />
                            </GridListTile>
                            </GridList>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="#/" onClick={() => setIsOpen(true)} className="card-img-wrapper rounded-right">
                            <GridList cellHeight={160}  cols={1}>
                                <GridListTile key={headerPhotos[2].photo} >
                                    <img src={headerPhotos[2].photo} alt="Fotos del Centro" />
                                </GridListTile>
                            </GridList>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <ReviewsContext.Provider value={[reviewsContext, setReviewsContext]}>
                <CenterStatistics center_slug={center.slug} />
                <CenterDescription {...center} />
                <CenterFacilities facilities={center.facilities} />
                <CenterReviews center_slug={center.slug} />
                <CenterTopDiverList {...center} />
                <CenterMap {...center} />
            </ReviewsContext.Provider>
            <ReactBnbGallery
                show={isOpen}
                photos={gallery}
                onClose={() => setIsOpen(false)}
            />
        </Container>

    );
}

export default Center;