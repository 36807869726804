import React, {useEffect} from "react"
import {Container, Grid, Typography, Card, CardContent} from "@material-ui/core";
import YoutubeEmbed from "../components/YoutubeEmbed";

import hero from  '../assets/images/hero-bg/hero-2.jpg'

const HowTo = () => {
    useEffect(() => {
        document.title = "Aprende sobre Gungo"
    }, [])
    return (
        <div className="hero-wrapper bg-composed-wrapper bg-second z-below">
            <div className="hero-wrapper--content flex-column">
                <div className="shape-container-bottom-2 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1" 
                            d="M0,128L80,154.7C160,181,320,235,480,224C640,213,800,139,960,101.3C1120,64,1280,64,1360,64L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z">
                        </path>
                    </svg>
                </div>
                <div
                    className="bg-composed-wrapper--image opacity-3"
                    style={{ backgroundImage: 'url(' + hero + ')' }}
                />
                <div className="bg-composed-wrapper--bg bg-night-sky opacity-4" />
                <div className="bg-composed-wrapper--content pb-5">
                    <Container fixed maxWidth="md" className="py-4">
                        <div className="py-5 py-sm-0">
                            <Typography variant="h3" className="font-weight-bold text-white mb-3">¿Que puedo hacer en gungo?</Typography>
                            <Grid item xs={12}>
                                <Typography variant="h5" className="font-weight-bold text-white mb-3">Registra tus visitas y ver estadisticas</Typography>
                                <Card className="mb-5">
                                    <CardContent>
                                        <YoutubeEmbed embedId="ROshVbDQFR0" />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" className="font-weight-bold text-white mb-3">Encuentra tu centro de buceo favorito</Typography>
                                <Card className="mb-5">
                                    <CardContent>
                                        <YoutubeEmbed embedId="ECpUdDFJSBw" />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography variant="h5" className="font-weight-bold text-white mb-3">Ver tus logros</Typography>
                                <Card className="mb-5">
                                    <CardContent>
                                        <YoutubeEmbed embedId="R5tAc7ZwkE8" />
                                    </CardContent>
                                </Card>
                            </Grid> */}
                        </div>
                    </Container>
                </div>
                <div className="shape-container-top-1 w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="var(--white)"
                            fillOpacity="1"
                            d="M0,256L24,224C48,192,96,128,144,133.3C192,139,240,213,288,218.7C336,224,384,160,432,138.7C480,117,528,139,576,138.7C624,139,672,117,720,138.7C768,160,816,224,864,250.7C912,277,960,267,1008,229.3C1056,192,1104,128,1152,138.7C1200,149,1248,235,1296,250.7C1344,267,1392,213,1416,186.7L1440,160L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default HowTo