import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { CardContent, makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'

const useStyle = makeStyles(() => ({
    centerRating: {
        position: "absolute",
        top: 10,
        right: 10
    }
}));
const CardCenter = (props) => {
    const classes = useStyle();
    const description_stripped = (props.description == null) ? "" : props.description.replace(/<[^>]+>/g, '')
    return (
        <Link
            className="card card-box-hover-rise bg-white" style={{minHeight: '100%'}}
            to={`/center/${props.slug}`}>
            <div className="card-badges">
                <div className="badge badge-pill badge-warning">{props.zone}</div>
            </div>
            <img alt={props.name} className="card-img-top" src={props.image_small} />
            <CardContent className="card-body-avatar px-4 pb-4">
                <div className="avatar-icon-wrapper rounded-pill border-white border-3 avatar-icon-wrapper--sm">
                    <div className="avatar-icon rounded-pill">
                        <img alt={props.name} src={props.logo_thumb} />
                    </div>
                </div>
                <div className={clsx(classes.centerRating)}>
                    <Rating value={parseInt(props.rating)} readOnly size="small" />
                </div>
                <h5 className="card-title font-weight-bold font-size-xl">{props.name}</h5>
                <p className="card-text">
                    {!description_stripped ? "" : description_stripped.substring(0, 200) + "..."}
                </p>
                {/*<div className="card-date text-black-50 mt-2">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-1"/>
                    <small>added moments ago</small>
                </div>*/}
            </CardContent>
        </Link>
    );
}

export default CardCenter;