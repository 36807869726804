import React, {useEffect, useState} from "react";
import { Grid, InputAdornment, Button, TextField, Snackbar } from '@material-ui/core';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import MuiAlert from "@material-ui/lab/Alert";
import {useHistory, useLocation} from 'react-router-dom';
import {fetchAPI} from "../api"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const NewPassword = () => {
    useEffect(() => {
        document.title = "Cambiar Password - Gungo"
    }, [])

    const history = useHistory()
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [sent, setSent] = useState(false)
    const [confirmation, setConfirmation] = useState({
        open: false,
        message: '',
        type: ''
    })
    let queryParams = useQuery()
    const token = queryParams.get("reset_password_token") || ""

    const handleCloseConfirmation = () => {
        setConfirmation((s) => ({ ...s, open: false }))
    }

    const handleButtonClick = () => {
        if (sent) history.push('/login')

        if (!token) {
            setConfirmation({
                open: true,
                type: 'warning',
                message: 'Link de cambio de password incorrecto'
            })
            return
        }

        if (password !== passwordConfirmation) {
            setConfirmation({
                open: true,
                type: 'warning',
                message: 'Las contraseñas tienen que coincidir'
            })
            return
        }
        if (password.length < 6) {
            setConfirmation({
                open: true,
                type: 'warning',
                message: 'La contraseña tiene que tener minimo 6 caracteres'
            })
            return
        }
        const payload = {
            user: {
                reset_password_token: token,
                password: password,
                password_confirmation: passwordConfirmation
            }
        }
        console.log(JSON.stringify(payload))

        fetchAPI('PUT', '/password', payload, false)
            .then(data => {
                if (data.errors) {
                    setConfirmation({
                        open: true,
                        type: 'warning',
                        message: 'Algo Salio mal al cambiar tu contraseña'
                    })
                    return
                }
                setSent(true)
            })
    }


    return (
        <>
            <div className="app-wrapper bg-white min-vh-100">
                <div className="app-main min-vh-100">
                    <div className="app-content p-0">
                        <div className="app-content--inner d-flex align-items-center">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content py-5">
                                    <Grid
                                        container
                                        item
                                        md={10}
                                        lg={8}
                                        xl={4}
                                        className="mx-auto"
                                        spacing={2}>
                                        <Grid item xs={12} className="text-center">
                                            <h1 className="display-4 mb-1 font-weight-bold">
                                                Nueva contraseña
                                            </h1>
                                            <p className="font-size-lg mb-0 text-black-50">
                                                {sent
                                                    ? 'Tu contraseña ha sido reseteada, por favor inicia sesión'
                                                    : 'Escribe tu nueva contraseña'}
                                            </p>
                                        </Grid>
                                        {!sent && (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="password"
                                                        label="Contraseña"
                                                        value={password}
                                                        type="password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="password_confirmation"
                                                        label="Cornfirma contraseña"
                                                        value={passwordConfirmation}
                                                        type="password"
                                                        onChange={(e) =>
                                                            setPasswordConfirmation(e.target.value)
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LockTwoToneIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        <Grid item xs={12} container alignContent="center">
                                            <Button
                                                fullWidth
                                                onClick={handleButtonClick}
                                                variant="contained"
                                                className="btn-second font-weight-bold my-2 py-3"
                                            >
                                                {sent ? 'Iniciar sesion' : 'Continuar'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={confirmation.open} autoHideDuration={6000} onClose={handleCloseConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseConfirmation} severity={confirmation.type}>
                    {confirmation.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default NewPassword