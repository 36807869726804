import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Grid } from '@material-ui/core';

import Slider from 'react-slick';

import hero3 from '../assets/images/hero-bg/hero-3.jpg';
import { fetchAPI } from "../api";
import ExperienceCard from "./ExperienceCard";

function SliderArrowNext(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </div>
    );
}

function SliderArrowPrev(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </div>
    );
}

const CarouselReviews = () => {
    const slicerConfig = {
        dots: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderArrowNext />,
        prevArrow: <SliderArrowPrev />,
        responsive: [
            {
                breakpoint: 1100,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ]
    };

    const [reviews, setReviews] = useState([]);
    
    useEffect(() => {
        fetchAPI('GET', '/last_reviews', '', false)
        .then(data => { setReviews(data) }).catch(error =>
            console.log("error al conectar con API")
        )
    }, []);

    return (
        <Container maxWidth={false} className="bg-midnight-bloom bg-composed-wrapper hero-wrapper my-4 flex-grow-1 w-100 d-flex align-items-center">
            <div
                className="bg-composed-wrapper--image"
                style={{ backgroundImage: 'url(' + hero3 + ')' }}
            />
            <div className="bg-composed-wrapper--bg bg-primary opacity-2" />
            <div className="bg-composed-wrapper--content py-5">
                <Container>
                    <div className="text-white text-center">
                        <h2 className="display-2 mb-2 px-4 font-weight-bold">
                            Ultimas Experiencias
                        </h2>
                        <h3 className="font-size-lg line-height-md font-weight-light d-block mb-0 text-white-50">
                            Experiencias de buzos que han visitado centros recientemente
                        </h3>
                    </div>
                    <Grid item xl={10} className="mx-auto">
                        <Slider
                            {...slicerConfig}
                            className="slider-dots-light slider-arrows-outside">
                            {reviews.map((review) => (
                                <ExperienceCard key={review.id} {...review}  />
                            ))}
                        </Slider>
                    </Grid>
                </Container>
            </div>
        </Container>
    );
}

export default CarouselReviews;