import React, {useEffect, useState} from "react";
import {Card} from "@material-ui/core";
import Chart from 'react-apexcharts';
import {fetchAPI} from "../api";
import {useAuthState} from "../Context";

const MyDivesChart = () => {
    const userDetails = useAuthState()

    const [chartData, setChartData] = useState({
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        data: [0,0,0,0,0,0,0,0,0,0,0,0]

    });

    useEffect(() => {
        fetchAPI('GET', '/users/me/dives_chart', '', true)
            .then(data => {
                setChartData(data);
            })
    }, [userDetails])

    const options = {
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: chartData.months
        }
    };
    const series = [
        {
            name: 'buceos',
            color: '#F4772E',
            data: chartData.data
        }
    ];

    return (
        <Card className="card-box">
            <div className="card-header">
                <div className="card-header--title">
                    <small className="d-block text-uppercase mt-1">
                        últimos 12 meses
                    </small>
                    <b>Mis Buceos por mes</b>
                </div>
            </div>
            <div className="card-body">
                <Chart options={options} series={series} type="bar" height={198} />
            </div>
        </Card>
    );
}

export default MyDivesChart;