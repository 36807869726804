import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogContent,
    TextField,
    Box, Checkbox, FormControlLabel, Snackbar
} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';

import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import LoginWarning from "../LoginWarning";
import {useAuthState} from "../../Context";
import {fetchAPI} from "../../api";
import {ReviewsContext} from "../../Context";
import MuiAlert from "@material-ui/lab/Alert";

const labels = {
    1: 'Muy malo',
    2: 'Malo',
    3: 'Meh',
    4: 'Bueno',
    5: 'Excelente',
};

const useStyles = makeStyles({
    root: {

        display: 'flex',
        alignItems: 'center',
    },
    AvgRating: {
        justifyContent: "center"
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CenterAddReview = (props) => {
    const [reviewsContext, setReviewsContext] = useContext(ReviewsContext)
    const userDetails = useAuthState()
    const [open, setOpen] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)
    const [buttonStatus, setButtonStatus] = useState(false)

    const handleClickOpen = () => {
        if (userDetails.token) {
            setOpen(true)
        } else {
            setOpenWarning(true)
        }
    };

    const handleClose = () => {
        if (userDetails.token) {
            setOpen(false)
        } else {
            setOpenWarning(false)
        }
    };

    const [staff, setStaff] = useState(0);
    const [hoverStaff, setHoverStaff] = useState(-1);

    const [equipment, setEquipment] = useState(0);
    const [hoverEquipment, setHoverEquipment] = useState(-1);

    const [infrastructure, setInfrastructure] = useState(0);
    const [hoverInfrastructure, setHoverInfrastructure] = useState(-1);

    const [safety, setSafety] = useState(0);
    const [hoverSafety, setHoverSafety] = useState(-1);

    const [avg, setAvg] = useState(0)
    useEffect(() => {
        const avg = (staff + equipment + infrastructure + safety)/4
        setAvg(avg)
    }, [staff, equipment, infrastructure, safety])

    const [comment, setComment] = useState("")
    const handleChangeComment = (e) => {
        setComment(e.target.value)
    }

    const [showName, setShowName] = useState(true)
    const handleChangeShowName = () => {
        setShowName(!showName)
    }

    const [hasReview, setHasReview] = useState(false)
    useEffect(() => {
        const findReview = props.reviews.findIndex((r) => r.user_id === userDetails.user.id)
        const findReviewBool = (findReview >= 0)
        setHasReview(findReviewBool)
    }, [props.reviews])

    const sendReview = () => {
        setButtonStatus(true)
        if ( avg > 0 ) {
            const path = `/centers/${props.center_slug}/reviews`
            const payload = {
                review: {
                    rating: avg,
                    rating_safety: safety,
                    rating_staff: staff,
                    rating_equipment: equipment,
                    rating_infrastructure: infrastructure,
                    comment: comment,
                    show_name: showName
                }
            }

            fetchAPI('POST', path, payload, true)
                .then(data => {
                    if (data.id > 0) {
                        setConfirmation({
                            open: true,
                            type: 'success',
                            message: `Recibimos tu Review. Sumaste Gungo Puntos!`
                        })

                        setOpen(false)
                        setStaff(0)
                        setHoverStaff(-1)
                        setEquipment(0)
                        setHoverEquipment(-1)
                        setInfrastructure(0)
                        setHoverInfrastructure(-1)
                        setSafety(0)
                        setHoverSafety(-1)
                        setComment('')
                        setButtonStatus(false)

                        fetchAPI('GET', `/centers/${props.center_slug}/reviews`, '', false)
                            .then(response => {
                                setReviewsContext(response)
                            })
                    }
                })
        }
    }

    const [confirmation, setConfirmation] = useState({
        open: false,
        message: '',
        type: ''
    })

    const handleCloseConfirmation = () => {
        setConfirmation((s) => ({ ...s, open: false }))
    }

    const classes = useStyles();
    return (
        <>
            <Button
                className="btn-warning m-2"
                onClick={handleClickOpen}
                disabled={hasReview ? true : false}
            >
                <span className="btn-wrapper--label">{hasReview ? 'Ya Dejaste un Review' : 'Deja Tu Review'}</span>
                <span className="btn-wrapper--icon">
                    <StarTwoToneIcon />
                </span>
            </Button>

            <Dialog
                classes={{ paper: 'modal-content rounded-lg' }}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="review-modal-form">
                <DialogContent className="p-0">
                    <Card className="shadow-none bg-transparent p-4 border-0">
                        <CardHeader title="Dejar una Reseña" />
                        <div>
                            <div className={clsx(classes.root, classes.AvgRating)}>
                                <Rating
                                    name="avg"
                                    value={avg}
                                    readOnly
                                    size="large"
                                />
                            </div>
                        </div>
                        <div className="divider my-2"/>
                        <div>
                            <div className="heading-3">Que te pareció el Personal?</div>
                            <div className={classes.root}>
                                <Rating
                                    name="staff"
                                    value={staff}
                                    onChange={(event, newValue) => {
                                        setStaff(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHoverStaff(newHover);
                                    }}
                                />
                                {staff !== null && <Box ml={2} className="badge badge-warning">{labels[hoverStaff !== -1 ? hoverStaff : staff]}</Box>}
                            </div>
                        </div>
                        <div className="divider my-2"/>
                        <div>
                            <div className="heading-3">Que te pareció el Equipamiento?</div>
                            <div className={classes.root}>
                                <Rating
                                    name="equipment"
                                    value={equipment}
                                    onChange={(event, newValue) => {
                                        setEquipment(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHoverEquipment(newHover);
                                    }}
                                />
                                {equipment !== null && <Box ml={2} className="badge badge-warning">{labels[hoverEquipment !== -1 ? hoverEquipment : equipment]}</Box>}
                            </div>
                        </div>
                        <div className="divider my-2"/>
                        <div>
                            <div className="heading-3">Que te pareció la Infraestructura?</div>
                            <div className={classes.root}>
                                <Rating
                                    name="infrastructure"
                                    value={infrastructure}
                                    onChange={(event, newValue) => {
                                        setInfrastructure(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHoverInfrastructure(newHover);
                                    }}
                                />
                                {infrastructure !== null && <Box ml={2} className="badge badge-warning">{labels[hoverInfrastructure !== -1 ? hoverInfrastructure : infrastructure]}</Box>}
                            </div>
                        </div>
                        <div className="divider my-2"/>
                        <div>
                            <div className="heading-3">Que te pareció la Seguridad?</div>
                            <div className={classes.root}>
                                <Rating
                                    name="safety"
                                    value={safety}
                                    onChange={(event, newValue) => {
                                        setSafety(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHoverSafety(newHover);
                                    }}
                                />
                                {safety !== null && <Box ml={2} className="badge badge-warning">{labels[hoverSafety !== -1 ? hoverSafety : safety]}</Box>}
                            </div>
                        </div>
                        <div className="divider my-2"/>
                        <div>
                            <div className="heading-3">Quieres Dejar un Comentario?</div>

                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    id="comment"
                                    label="Comentario"
                                    value={comment}
                                    onChange={handleChangeComment}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showName}
                                            onChange={handleChangeShowName}
                                            value="1"
                                            color="primary"
                                        />
                                    }
                                    className="font-size-md"
                                    label="Mostrar mi nombre en el review"
                                />
                            </div>
                            <div className="text-center">
                                <Button
                                    disabled={buttonStatus}
                                    variant="contained"
                                    className="font-weight-bold btn-second px-4 my-3"
                                onClick={sendReview}>
                                    Guardar Review
                                </Button>
                            </div>
                        </div>
                    </Card>
                </DialogContent>
            </Dialog>
            <Snackbar open={confirmation.open} autoHideDuration={6000} onClose={handleCloseConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseConfirmation} severity={confirmation.type}>
                    {confirmation.message}
                </Alert>
            </Snackbar>

            <LoginWarning open={openWarning} handleClose={handleClose} message="Para dejar una reseña debes ser usuario de Gungo." />
        </>
    );
}

export default CenterAddReview;