import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom"

import {Container, Grid} from '@material-ui/core';

import PageTitle from '../layouts/PageTitle';
import CenterCard from "../components/CenterCard";
import {fetchAPI} from "../api";
import CentersFilterDialog from "../components/CentersFilterDialog";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Centers = () => {

    useEffect(() => {
        document.title = "Buscar centros de buceo en Gungo"
    }, [])

    const [allCenters, setAllCenters] = useState([])
    const [filteredCenters, setFilteredCenters] = useState([])

    let queryParams = useQuery()

    const [searchQ, setSearchQ] = useState(queryParams.get("q") || "")
    const [searchZ, setSearchZ] = useState(() => {
        const zones = queryParams.getAll("z")
        let obj_zones = {
            norte: false,
            centro: false,
            sur: false,
            rapanui: false
          }
        zones.forEach(z => {obj_zones[z] = true})

        return obj_zones
    })
    const [locations, setLocations] = useState([])
    const [countFilteredCenters, setCountFilteredCenters] = useState(0)

    const fetchData = () => {
        fetchAPI('GET', '/centers', '', false)
        .then(data => {
            setAllCenters(data)
        }).catch(error =>
            console.log("error al conectar con API")
        )
    }
    useEffect(() => { fetchData() }, [])

    useEffect(() => {
        setCountFilteredCenters(filteredCenters.length)
    }, [filteredCenters])

    async function getLocations(){
        const APILocations = await fetchAPI('GET', '/locations', '', false)
        const locationParams = queryParams.getAll("l") || []
        APILocations.forEach(l => { l['checked'] = !!(locationParams.includes(l.id.toString())) })
        setLocations(APILocations)
    }
    useEffect(() => { getLocations() }, [])

    useEffect(() => {
        let centers = allCenters
        if (searchQ !== null) {
            centers = centers.filter(center => {
                return center.name.toLowerCase().includes(searchQ.toLowerCase())
                })
        }
        let filtered_zones = []
        Object.keys(searchZ).forEach(k => {
            if (searchZ[k] === true) {
                filtered_zones.push(k)
            }
        })
        if (filtered_zones.length > 0) {
            centers = centers.filter(center => {
                return filtered_zones.includes(center.zone.toLowerCase())
            })
        }
        
        const selectedLocations = locations.filter(l => l.checked).map(l => l.id)
        if (selectedLocations !== undefined && selectedLocations.length > 0) {
            centers = centers.filter(center => {
                let center_locations = center.locations.map(cl => cl.id)
                return selectedLocations.some(l => center_locations.includes(l))
            })
        }

        setFilteredCenters(centers)
    }, [allCenters, locations, searchQ, searchZ])

    const handleChangeName = (event) => {
        setSearchQ(event.target.value)
    }

    const handleChangeZone = (event) => {
        setSearchZ({ ...searchZ, [event.target.name]: event.target.checked });
    }

    const handleChangeLocations = (event) => {
        const li = locations.findIndex((l) => l.id === parseInt(event.target.id));
        locations[li].checked = event.target.checked

        setLocations([...locations])
    };

    return(
        <Container fixed maxWidth="lg" className="mt-2">
            <PageTitle
                titleHeading="Buscar Centros de Buceo"
                titleDescription="Busca dónde bucear en Chile"
                iconHeader="https://cdn.gungo.cl/gungo/web/gungo-lupa-cuadrado-100.png">
            </PageTitle>

            <CentersFilterDialog
                onChangeName={handleChangeName}
                onChangeZone={handleChangeZone}
                onChangeLocation={handleChangeLocations}
                name={searchQ}
                zones={searchZ}
                locations={locations}
                countFilteredCenters={countFilteredCenters}
            />

            <Grid container spacing={2}>
                <Grid item lg={12}>
                    {filteredCenters.map((center) => (
                        <CenterCard key={center.id} {...center} />
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
}

export default Centers;