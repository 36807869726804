import React from "react";
import {Grid, LinearProgress, ListItem} from "@material-ui/core";

const CenterTopDiver = (props) => {
    const MAX_DIVES = 30
    const COLORS = ["primary", "danger", "warning", "success", "primary"]
    return (
        <ListItem className="py-3">
            <Grid container spacing={0}>
                <Grid
                    item
                    xl={6}
                    md={12}
                    className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper mr-2">
                            <div className="avatar-icon">
                                <img src="https://cdn.gungo.cl/gungo/default/user-avatar.png" alt={props.full_name} />
                            </div>
                        </div>
                        <div>
                            <strong
                                className="font-weight-bold text-black">
                                {props.full_name}
                            </strong>
                            <span className="text-black-50 d-block">
                              Nivel {props.level} - Gungo ptos.
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid
                    item
                    xl={6}
                    md={12}
                    className="pt-3 pt-xl-0 d-flex align-items-center">
                    <div className="align-box-row flex-grow-1">
                        <div className="d-flex flex-column flex-grow-1">
                            <div className="d-flex justify-content-between text-dark">
                                <div className="ml-auto">
                                    <div className="font-weight-bold">
                                        Buceos: {props.dives}
                                    </div>
                                </div>
                            </div>
                            <LinearProgress
                                variant="determinate"
                                className={`progress-sm progress-bar-rounded progress-bar-${COLORS[props.index]}`}
                                value={(props.dives*100)/MAX_DIVES}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default CenterTopDiver