import React, {useEffect, useState} from "react";

import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Snackbar
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCommentTwoToneIcon from "@material-ui/icons/AddCommentTwoTone";
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@material-ui/icons/ThumbDownTwoTone';
import {fetchAPI} from "../api";
import MuiAlert from '@material-ui/lab/Alert';

import { useAuthState, useAuthDispatch, reloadUser } from '../Context'
import LoginWarning from "./LoginWarning";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CheckIn(props) {

    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const [open, setOpen] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)

    const handleClickOpen = () => {
        if (userDetails.token) {
            setOpen(true)
        } else {
            setOpenWarning(true)
        }

    };

    const handleClose = () => {
        if (userDetails.token) {
            setOpen(false)
        } else {
            setOpenWarning(false)
        }
    }

    const[centers, setCenters] = useState([])
    const [center, setCenter] = useState(props.hasOwnProperty('center') ? props.center : {})
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [experience, setExperience] = useState('')
    const [errors, setErrors] = useState({
        centerError: false,
        centerMessage: '',
        visitError: false,
        visitMessage: ''
    })

    useEffect(()=> {
        if (centers.length < 1) {
            fetchAPI('GET', '/centers', '', false)
                .then(data => {
                    setCenters(data)
                })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const handleChangeExperience = (event, newExperience) => {
        setExperience(newExperience);
    };

    const handleRegister = () => {

        if (experience && selectedDate && center.hasOwnProperty('slug') && center.slug !== undefined) {
            const path = `/centers/${center.slug}/checkins`
            let positiveExperience = false
            let negativeExperience = false
            if (experience === "positive") {
                positiveExperience = true
            } else if (experience === "negative") {
                negativeExperience = true
            }

            const payload = {
                checkin: {
                    positive: positiveExperience,
                    negative: negativeExperience,
                    visit_date: format(selectedDate, 'dd/MM/yyyy')
                }
            }

            fetchAPI('POST', path, payload, true)
                .then(data => {
                    if (data.id > 0) {
                        setConfirmation({
                            open: true,
                            type: 'success',
                            message: `Tu visita a ${center.name} fue registrada correctamente!`
                        })
                        setOpen(false)
                        const reloadedUser = reloadUser(dispatch)
                    }
                })
        } else {
            if (!center.hasOwnProperty('slug') || center.slug === undefined) {
                setErrors((e) => ({ ...e, centerError: true, centerMessage: 'Debes elegir un centro' }))
            } else {
                setErrors((e) => ({ ...e, centerError: false, centerMessage: '' }))
            }
            if (!selectedDate) {
                setErrors((e) => ({ ...e, visitError: true, visitMessage: 'Debes elegir una fecha' }))
            } else {
                setErrors((e) => ({ ...e, visitError: false, visitMessage: '' }))
            }
        }


    }

    const [confirmation, setConfirmation] = useState({
        open: false,
        message: '',
        type: ''
    })

    const handleCloseConfirmation = () => {
        setConfirmation((s) => ({ ...s, open: false }))
    }

    return(
        <>
            <Button
                variant="contained"
                className="btn-success"
                onClick={handleClickOpen}>
                <span className="btn-wrapper--label">Check In</span>
                <span className="btn-wrapper--icon">
                        <AddCommentTwoToneIcon />
                </span>
            </Button>
            <Dialog
                classes={{ paper: 'modal-content rounded-lg modal-white' }}
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Registra tu Visita a un Centro</DialogTitle>
                <DialogContent className="p-4">
                    <DialogContentText>
                        Primero selecciona el centro
                    </DialogContentText>
                    <DialogContentText className="mb-0">
                        <Autocomplete
                            id="center"
                            //defaultValue={props.center}
                            options={centers}
                            getOptionSelected={(option, value) => option.slug === value.slug}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => { setCenter(newValue); }}
                            renderInput={(params) => <TextField {...params} label="Buscar Centro" variant="outlined" error={errors.centerError} helperText={errors.centerMessage} />}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <div>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Fecha de Visita"
                                    value={selectedDate}
                                    locale="es"
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    error={errors.visitError}
                                    helperText={errors.visitMessage}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <DialogContentText>Como fue tu experiencia? </DialogContentText>
                        <ToggleButtonGroup size="large" value={experience} exclusive onChange={handleChangeExperience} error helperText="selecciona una opción">
                            <ToggleButton value="positive" classes={{ selected: 'border-2' }} error>
                                <ThumbUpTwoToneIcon htmlColor="#1BC943" />
                            </ToggleButton>
                            <ToggleButton value="negative" classes={{ selected: 'border-2' }}>
                                <ThumbDownTwoToneIcon color="error" />
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="p-4">
                    <Button onClick={handleRegister} className="btn-success shadow-none">
                        Registrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={confirmation.open} autoHideDuration={6000} onClose={handleCloseConfirmation} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseConfirmation} severity={confirmation.type}>
                    {confirmation.message}
                </Alert>
            </Snackbar>

            <LoginWarning open={openWarning} handleClose={handleClose} message="Para poder registrar una visita a un centro de buceo tienes que tener un usuario en Gungo." />
        </>
    );
}