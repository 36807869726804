import React, {createContext, useEffect, useReducer} from 'react';
import { initialState, AuthReducer } from './reducer';

import { logout } from '../Context';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider');
    }

    return context;
}

export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    useEffect(() => {
        const expiration = localStorage.getItem('expiration');
        if (!user || parseInt(expiration) < Date.now()) {
            logout(dispatch);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

export const ReviewsContext = createContext();