import React, { useState } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";

import {Grid, Card, CardContent, TextField, InputAdornment, Button, makeStyles, Container} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import AutocompleteField from "./AutocompleteField";

const useStyles = makeStyles( () => ({
    searchButton: {
        paddingTop: 11,
        paddingBottom: 11
    },
    searchContainer: {
        backgroundImage: 'url(https://cdn.gungo.cl/gungo/images/bg-home.jpg)',
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: 400,
        display: "flex",
        alignItems: "center"
    },
    searchBox: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    }
}));

const SearchBox = () => {
    const [searchStatus, setSearchStatus] = useState(false);
    const toggleSearch = () => setSearchStatus(!searchStatus);
    const classes = useStyles();

    const [search, setSearch] = useState()

    //const fixedOptions = []
    const [locationField, setLocationField] = useState([])

    const handleChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleChangeLocation = (event, newValue) => {
        setLocationField(newValue);
    };

    const handleKeypress = e => {
        if (e.key === "Enter") {
            handleClick();
        }
    };

    let history = useHistory();
    const handleClick = () => {

        let locationParams = "";
        locationParams = (search == null) ?  "?q=" : `?q=${search}`
        for (let location of locationField) {
            locationParams += `&l=${location['id']}`
        };
        history.push(`/centers${locationParams}`);
    }


    return (
        <Container maxWidth={false} className={clsx(classes.searchContainer)}>
        <Grid container justify="center">
            <Card className={clsx(classes.searchBox,'p-2', 'my-2', 'card-box', 'card-box-border-bottom', 'border-warning', 'rounded-sm')}>
                <CardContent>
                    <Grid container spacing={3} className="text-center" justify="center">
                        <Grid item>
                            <h3>Elije tu proxima aventura de Buceo</h3>
                            <h5>Encuentrala aquí!</h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="text-center" justify="center">
                        <Grid item md={12} >
                            <Link to="/centers?z=norte">
                                <Button className="m-2 btn-neutral-primary" size="large">Norte</Button>
                            </Link>
                            <Link to="/centers?z=centro">
                                <Button className="m-2 btn-neutral-primary" size="large">Litoral Central</Button>
                            </Link>
                            <Link to="/centers?z=rapanui">
                                <Button className="m-2 btn-neutral-primary" size="large">Rapa Nui</Button>
                            </Link>
                            <Link to="/centers?z=sur">
                                <Button className="m-2 btn-neutral-primary" size="large">Sur</Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="text-center" justify="center">
                        <Grid item md={3} >
                            <AutocompleteField onChange={handleChangeLocation} />
                        </Grid>
                        <Grid item md={5} >
                            <TextField
                                variant="outlined"
                                size="small"
                                id="input-with-icon-textfield22-1"
                                className="w-100"
                                name="q"
                                onFocus={toggleSearch}
                                onBlur={toggleSearch}
                                onChange={handleChangeSearch}
                                onKeyPress={handleKeypress}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Link to="/centers" style={{float: 'right', textDecoration: 'underline'}}>Ver Todos los Centros</Link>
                        </Grid>
                        <Grid item md={4} >
                            <Button type="submit" size="large" variant="contained" className={clsx(classes.searchButton, 'btn-primary')} onClick={handleClick} >
                                <span className="btn-wrapper--label">Buscar</span>
                                <span className="btn-wrapper--icon">
                                    <SearchTwoToneIcon />
                                </span>
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        </Container>
    );
}

export default SearchBox;