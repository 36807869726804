import React from "react";
import {Grid} from "@material-ui/core";

import GoogleMapReact from 'google-map-react';

const MapMarker = ({ logo }) => (
    <div className="avatar-icon-wrapper shadow-lg border-white rounded m-3 border-3">
        <div className="avatar-icon shadow-none d-40 rounded">
            <img src={logo} alt="" />
        </div>
    </div>)

const CenterMap = (props) => {

    const center = {
        lat: props.lat ? parseFloat(props.lat) : -33.447487,
        lng: props.lng ? parseFloat(props.lng) : -70.673676
    }
    const zoom = 15;

    return (
        <Grid container spacing={2} className="p-2">
            <Grid item xs={12}>
                <div className="divider my-4" />
                <h2>Ubicación</h2>
                <div className="w-100" style={{ height: '350px' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyB1P0pJn_hBMdE9V8KwqgAJZrK_pfSqw7I' }}
                        center={center}
                        defaultZoom={zoom}
                    >
                        <MapMarker lat={center.lat} lng={center.lng} logo={props.logo_thumb} />
                    </GoogleMapReact>
                </div>
            </Grid>
            <Grid item xs={12}>
                {props.address ? props.address : "Sin Dirección" }
            </Grid>
        </Grid>
    );
}

export default CenterMap;